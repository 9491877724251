import axios from 'axios';
//import toast from 'react-hot-toast';
import { ToastContainer, toast } from 'react-toastify';

const baseURL = "https://azureapi.orbina.ai/";

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if(config?.url == "/ai/generateimage" || config?.url.includes("/ai/generatesocial") ){
    config.headers['Content-Type'] =  'application/x-www-form-urlencoded'
  }
  if(config?.url == "/users/updateprofile" || config?.url.includes("/users/updateprofile") ){
    config.headers['Content-Type'] =  'multipart/form-data'
  }
  return config;
});

instance.interceptors.response.use(
  (response) =>  response,
  (error) => {
    if (error?.response?.status === 403) {
      toast.error('Your session has expired. Please login again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    try {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } catch (e) {
      toast.error('Something went wrong. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    return error;
  }
);

const fetcher = (url) => instance.get(url).then((res) => res.data);

export { instance, fetcher };
