import React, { useState, useContext, useEffect } from "react";
import { Box, Text, Button, Divider, Link, useDisclosure, Image, Container } from "@chakra-ui/react";
import { AuthInput, AuthLayout, PasswordInput, LoadingModal } from "../../components";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/user";
import { UserContext } from "../../context/user";
import { useForm } from "react-hook-form";
import { loadAuth2 } from 'gapi-script';

const Login = () => {
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';

    script.onload = () => {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          client_id: '673280383907-0uabptdp5m0tj8bid29jn1enr34ugctp.apps.googleusercontent.com',
        })
          .then(() => {
            setIsLoaded(true);
          })
          .catch((error) => {
            console.error('Google Sign-In API initialization failed:', error);
          });
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleGoogleLogin = async () => {
    if (!isLoaded) {
      console.error('Google Sign-In API is not yet loaded.');
      return;
    }

    const auth2 = window.gapi.auth2.getAuthInstance();
    const response = await auth2.signIn();
    const idToken = response.getAuthResponse().id_token;

    // Send the idToken to the server
    const res = await fetch('https://azureapi.orbina.ai/users/google', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ idToken }),
    });

    if (res.ok) {
      const user = await res.json();
      dispatch({ type: 'LOGIN', payload: user });
      navigate(routes.dashboardHome.path);
    } else {
      // Handle error
      console.error('Google login failed');
    }
  };


  const [state, dispatch] = useContext(UserContext);
  const [user, setUser] = useState({ email: "", password: "" });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const loadingVideoModal = useDisclosure();

  const onSubmit = async () => {
    loadingVideoModal.onOpen();
    try {
      const response = await login(user);
      if (response?.status == 200 || response?.status == 201) {

        dispatch({ type: 'LOGIN', payload: response.data });
        navigate(routes.dashboardHome.path);
      }

    } catch (error) {
      console.log(error);
    }
    finally {
      loadingVideoModal.onClose();
    }
  };

  return (
    <AuthLayout>
      <Box display={"flex"} justifyContent="center">
        <Container
          maxW={{ base: "100%", md: "90%", lg: "100%" }}
          display="flex"
          justifyContent="center"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={"flex"}
              flexDirection="column"
              w={{ base: "100%", md: "90%", lg: "100%" }}
            >
              <Text fontWeight={"500"} fontSize="28" color={"white"} mb="5">
                Login to continue
              </Text>
              <Divider mb={4} color={"lightGray"} />
              <AuthInput
                type="email"
                label="Email"
                placeholder="Enter your email"
                _placeholder={{ opacity: 0.6 }}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
              <Box mb={4}></Box>
              <PasswordInput
                label="Password"
                placeholder="Enter your password"
                _placeholder={{ opacity: 0.6 }}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
              <Link
                _hover={{ textDecoration: "none" }}
                fontWeight={"600"}
                letterSpacing={"-0.04em"}
                fontSize="14"
                color={"gray"}
                alignSelf="flex-end"
                onClick={() => navigate(routes.forgotPassword.path)}
              >
                Forgot password?
              </Link>
              <Button
                backgroundColor={"pink"}
                borderRadius={"10"}
                height={"48px"}
                isFullWidth
                my={"5"}
                type="submit"
              //onClick={() => navigate(routes.dashboardHome.path)}
              >
                <Text color={"white"} fontWeight={"500"} fontSize={"16"}>
                  Continue
                </Text>
              </Button>
              <Text
                fontSize={"14"}
                fontWeight={"400"}
                color="gray"
                alignSelf={"center"}
              >
                Don’t have an account?{" "}
                <Link
                  _hover={{ textDecoration: "none" }}
                  color="gray"
                  fontWeight={"400"}
                  onClick={() => navigate(routes.register.path)}
                >
                  <Text as='u'>Sign Up.</Text>
                </Link>
              </Text>

              <Box
                display={"flex"}
                alignItems="center"
                justifyContent={"center"}
                mt="5"
              >
                <Divider w={"13vw"} color="lightGray" />
                <Text
                  color={"lightGray"}
                  fontWeight="400"
                  fontSize={"14"}
                  ml="5"
                  mr="5"
                >
                  OR
                </Text>
                <Divider w={"13vw"} color="lightGray" />
              </Box>

              <Button
                onClick={handleGoogleLogin}
                backgroundColor={"white"}
                borderRadius={"10"}
                height={"48px"}
                isFullWidth
                my={"5"}

              >
                <Box display="flex" alignItems="center">
                  <Image w="24px" src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"></Image>
                  <Text marginLeft="10px" color={"#121212"} fontWeight={"500"} fontSize={"16"}>
                    Continue with Google
                  </Text>
                </Box>

              </Button>
            </Box>
          </form>
        </Container>
      </Box>
      <LoadingModal
        onClose={loadingVideoModal.onClose}
        isOpen={loadingVideoModal.isOpen}
      />
    </AuthLayout>
  );
};

export default Login;
