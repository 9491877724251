import React from "react";

const Download = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 11.3332C1.63333 11.3332 1.31944 11.2026 1.05833 10.9415C0.79722 10.6804 0.666664 10.3665 0.666664 9.99984V7.99984H2V9.99984H10V7.99984H11.3333V9.99984C11.3333 10.3665 11.2028 10.6804 10.9417 10.9415C10.6806 11.2026 10.3667 11.3332 10 11.3332H2ZM6 8.6665L2.66666 5.33317L3.6 4.3665L5.33333 6.09984V0.666504H6.66666V6.09984L8.4 4.3665L9.33333 5.33317L6 8.6665Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Download;
