import React from "react";

const Creative = (props) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 20C7.56667 20 7.175 19.896 6.825 19.688C6.475 19.4793 6.2 19.2 6 18.85C5.45 18.85 4.97933 18.6543 4.588 18.263C4.196 17.871 4 17.4 4 16.85V13.3C3.01667 12.65 2.22933 11.7917 1.638 10.725C1.046 9.65833 0.75 8.5 0.75 7.25C0.75 5.23333 1.454 3.52067 2.862 2.112C4.27067 0.704 5.98333 0 8 0C10.0167 0 11.729 0.704 13.137 2.112C14.5457 3.52067 15.25 5.23333 15.25 7.25C15.25 8.53333 14.9543 9.7 14.363 10.75C13.771 11.8 12.9833 12.65 12 13.3V16.85C12 17.4 11.8043 17.871 11.413 18.263C11.021 18.6543 10.55 18.85 10 18.85C9.8 19.2 9.525 19.4793 9.175 19.688C8.825 19.896 8.43333 20 8 20ZM6 16.85H10V15.95H6V16.85ZM6 14.95H10V14H6V14.95ZM5.8 12H7.25V9.3L5.05 7.1L6.1 6.05L8 7.95L9.9 6.05L10.95 7.1L8.75 9.3V12H10.2C11.1 11.5667 11.8333 10.929 12.4 10.087C12.9667 9.24567 13.25 8.3 13.25 7.25C13.25 5.78333 12.7417 4.54167 11.725 3.525C10.7083 2.50833 9.46667 2 8 2C6.53333 2 5.29167 2.50833 4.275 3.525C3.25833 4.54167 2.75 5.78333 2.75 7.25C2.75 8.3 3.03333 9.24567 3.6 10.087C4.16667 10.929 4.9 11.5667 5.8 12Z"
        fill="#613DBB"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default Creative;
