import React from "react";

const BookMark = (props) => {
  return (
    <svg
      width="27"
      height="34"
      fontSize={30}
      viewBox="0 0 27 34"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 33.5V9.5C0 8.675 0.29375 7.96875 0.88125 7.38125C1.46875 6.79375 2.175 6.5 3 6.5H18C18.825 6.5 19.5312 6.79375 20.1188 7.38125C20.7063 7.96875 21 8.675 21 9.5V33.5L10.5 29L0 33.5ZM3 28.925L10.5 25.7L18 28.925V9.5H3V28.925ZM24 29V3.5H4.5V0.5H24C24.825 0.5 25.5312 0.79375 26.1188 1.38125C26.7063 1.96875 27 2.675 27 3.5V29H24Z"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default BookMark;
