import React from "react";
import { Box, Text, Icon } from "@chakra-ui/react";
import { DefaultButton } from "..";
import {
  Photo,
  Difference,
  Description,
  Creative,
  CaseStudies,
  Design,
  Inspirational,
  LinkednArticles,
  WebText,
  ChatBot,
} from "../icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

const NavbarMenu = () => {
  const navigate = useNavigate();
  return (
    <Box display={{ base: "column", md: "flex" }} gap="10" mt={"5"} p="1">
      <Box ml={"5"}>
        <Text fontWeight={"600"} fontSize="16" color={"orbPurple2"}>
          Features
        </Text>
        <IconwithTitle
          icon={Photo}
          text="Image Generation"
          onClick={() => navigate(routes.imageGeneration.path)}
        />
        <Text
          fontWeight={"500"}
          fontSize="14"
          color="#333333"
          mt={"-2"}
          ml={"8"}
        >
          Create Artistic <br />
          Images
        </Text>
        <IconwithTitle
          icon={Difference}
          text="Text Generation"
          onClick={() => navigate(routes.textGeneration.path)}
        />
        <Text
          fontWeight={"500"}
          fontSize="14"
          color="#333333"
          mt={"-2"}
          ml={"8"}
        >
          Meeting All Content
          <br />
          Needs For Articles
          <br />
        </Text>
        <IconwithTitle
          icon={Description}
          text="Templates"
          onClick={() => navigate(routes.templates.path)}
        />
        <DefaultButton
          as="button"
          color="white"
          backgroundColor="#613DBB"
          text={"ChatBot"}
          borderRadius={10}
          width="150px"
          leftIcon={<Icon as={ChatBot} color="white" />}
          onClick={() => console.log("")}
        />
      </Box>
      <Box ml={{ base: "5", md: "10" }}>
        <Text fontWeight={"600"} fontSize="16" color={"orbPurple2"}>
          Customized Generations
        </Text>
        <IconwithTitle icon={Inspirational} text="Inspirational Posts" />
        <IconwithTitle icon={WebText} text="LinkedIn Posts" />
        <IconwithTitle icon={LinkednArticles} text="Instagram Captions" />
        <IconwithTitle icon={CaseStudies} text="Google Ads" />
        <IconwithTitle icon={Creative} text="Facebook Ads" />
        <IconwithTitle icon={Design} text="Twitter Posts" />
        <IconwithTitle icon={Design} text="Medium Article" />
        <IconwithTitle icon={Design} text="Design & Social Media Strategies" />
      </Box>
    </Box>
  );
};

export default NavbarMenu;

const IconwithTitle = ({ onClick = () => {}, icon, text }) => {
  return (
    <Box
      display={"flex"}
      alignItems="center"
      gap={"3"}
      my={"4"}
      onClick={onClick}
      cursor="pointer"
    >
      <Icon as={icon} color="orbPurple2" boxSize={5} />
      <Text fontWeight={"600"} fontSize="16">
        {text}
      </Text>
    </Box>
  );
};
