import React, { useState } from "react";
import {
    Text,
    Box,
    Icon,
    Image,
    Divider,
    Textarea,
    useDisclosure,
    Badge,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Flex,
    Input,
    Container,
    Select,
    Checkbox
} from "@chakra-ui/react";
import {
    DefaultButton,
} from "../";
import images from "../../constants/images";
import { Formik, Field } from 'formik';


const initialValues = {
    name: '',
    email: '',
    year: '',
    cvv: '',
    month: '',
    description: '',
};

const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = 'Name field is required.';
    }

    if (!values.countryName) {
        errors.countryName = 'Country field is required.';
    }

    if (!values.month) {
        errors.month = 'Month field is required.';
    }

    if (!values.city) {
        errors.city = 'City field is required.';
    }

    if (!values.year) {
        errors.year = 'Postal Code field is required.';
    }

    if (!values.email) {
        errors.email = 'Email field is required.';
    }

    if (!values.description) {
        errors.description = 'Description field is required.';
    }

    return errors;
};


const customInputStyle = {
    borderRadius: '10px',
    border: '1px solid var(--orb-gray-5, rgba(125, 131, 152, 0.30))',
    background: 'var(--orb-white, #FFF)',
    boxShadow: '0px 3px 6px 0px rgba(18, 18, 18, 0.03)',
};


const PaymentModal = ({ onClose = () => { }, isOpen = false, modalType, currentData }) => {

    const handleSubmit = (values, actions) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }, 1000);
    };


    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    return (
        <Modal size="full" isOpen={isOpen} onClose={onClose} overFlow="hidden">
            <ModalOverlay bg={"rgba(162, 162, 162, 0.79)"} />
            <ModalContent boxShadow="none">

                <ModalBody p={0} >
                    {/* Use Flex with flexDirection="column" to split content */}
                    <Flex
                        flexDirection={{ base: "column", md: "row" }} // Column on mobile, row on medium and larger screens
                        justifyContent="space-between" // Space between the two parts
                        alignItems={{ base: "center", md: "flex-start" }} // Center on mobile, flex-start on medium and larger screens
                    >
                        <Box
                            width={{ base: "100%", md: "50%" }}
                            pt={4}
                            bg="linear-gradient(180deg, rgba(9, 27, 57, 0.83) 0%, #3A2D6B 34.38%, #64309B 66.15%, #79369C 100%)"
                            minH="100vh"
                        >

                            <Flex alignItems="center" mb={12}>
                                <Button color="white" fontSize={"40px"} variant="ghost" onClick={onClose}>
                                    {"<"}
                                </Button>
                                <Image src={images.logotitle} alt="Logo" w={{ base: "50%", sm: "30%", md: "20%", lg: "15%" }} />
                            </Flex>
                            <Box display={"flex"} justifyContent="center">

                                <Container
                                    maxW={{ base: "100%", md: "90%", lg: "100%" }}
                                    display="flex"
                                    justifyContent="center"
                                >


                                    <Flex flexDirection="column" justifyContent="center" mb={6}>

                                        <Text
                                            fontWeight="500"
                                            fontSize="32px"
                                            color="#fff"
                                            mt={12}
                                            mb={2}>
                                            Subscribe to <span style={{ fontSize: "45px" }}>Starter Plan</span> Product
                                        </Text>

                                        <Box display="flex" alignItems="flex-start" p="2" mb={12}>
                                            <Flex flex="1" pt={2} justifyContent="flex-start" alignItems="end">
                                                <Text fontWeight="400" color="#fff" fontSize="38">
                                                    <strong> $24,00 </strong>
                                                </Text>
                                                <Text fontWeight="500" color="#fff" fontSize="26">
                                                    &nbsp;  weekly
                                                </Text>
                                            </Flex>
                                        </Box>


                                        <Flex flexDirection="row" justifyContent="space-between" alignItems="baseline" mb={6}>
                                            <Text fontWeight="200" color="#fff" fontSize="26">
                                                Starter Plan
                                            </Text>
                                            <Text fontWeight="500" color="#fff" fontSize="26" ml="2">
                                                &nbsp;  $24,00
                                            </Text>
                                        </Flex>

                                        <FormControl mb={6} position="relative">
                                            <Input
                                                style={{ ...customInputStyle, color: "rgba(0, 0, 0, 1)" }}
                                                placeholder="orbina20"
                                            />
                                            <Button
                                                size="md"
                                                color="pink"
                                                position="absolute"
                                                top="50%"
                                                right="8px"
                                                transform="translateY(-50%)"
                                            >
                                                Apply
                                            </Button>
                                        </FormControl>

                                        <Divider mt={2} mb={8} color="white" />

                                        <Flex flexDirection="row" justifyContent="space-between" alignItems="baseline">
                                            <Text fontWeight="400" color="#fff" fontSize="26">
                                                Amount to be paid
                                            </Text>
                                            <Text fontWeight="400" color="#fff" fontSize="26" ml="2">
                                                &nbsp;  $24,00
                                            </Text>
                                        </Flex>

                                    </Flex>
                                </Container>
                            </Box>

                        </Box>


                        <Box width={{ base: "100%", md: "48%" }} pr={6}>


                            <Box height={{ base: "15vh", md: "15vh" }} />

                            <Text fontWeight="600" fontSize="34">
                                Pay with credit card
                            </Text>

                            <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
                                {({ handleSubmit, touched, errors, isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>

                                        <Field name="email">
                                            {({ field }) => (
                                                <FormControl mb={4} isInvalid={touched.email && errors.email}>
                                                    <FormLabel htmlFor="email">Email</FormLabel>
                                                    <Input
                                                        style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }}
                                                        {...field}
                                                        id="email"
                                                        placeholder="Enter your email" />
                                                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="description">
                                            {({ field }) => (
                                                <FormControl mb={4} isInvalid={touched.description && errors.description}>
                                                    <FormLabel htmlFor="description">Card Number</FormLabel>
                                                    <Input
                                                        style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }}
                                                        {...field}
                                                        id="description"
                                                        placeholder="Enter your Card number" />
                                                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="name">
                                            {({ field }) => (
                                                <FormControl mb={4} isInvalid={touched.name && errors.name}>
                                                    <FormLabel htmlFor="name">Name on card</FormLabel>
                                                    <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="name" placeholder="Enter the name on the card" />
                                                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>


                                        <FormLabel htmlFor="month"> Expiration Date </FormLabel>


                                        <div style={{ display: 'flex', gap: '20px' }}>
                                            <Field name="month">
                                                {({ field }) => (
                                                    <FormControl mb={4} isInvalid={touched.month && errors.month}>
                                                        <FormLabel htmlFor="month"></FormLabel>
                                                        <Select
                                                            style={{
                                                                ...customInputStyle,
                                                                color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)",
                                                            }}
                                                            {...field}
                                                            id="month"
                                                            placeholder="Month"
                                                        >
                                                            <option value="January">January</option>
                                                            <option value="February">February</option>
                                                            {/* Add more options for other months */}
                                                        </Select>
                                                        <FormErrorMessage>{errors.month}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>

                                            <Field name="year">
                                                {({ field }) => (
                                                    <FormControl mb={4} isInvalid={touched.year && errors.year}>
                                                        <FormLabel htmlFor="year"></FormLabel>
                                                        <Select
                                                            style={{
                                                                ...customInputStyle,
                                                                color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)",
                                                            }}
                                                            {...field}
                                                            id="year"
                                                            placeholder="Year"
                                                        >
                                                            <option value="2021">2021</option>
                                                            <option value="2022">2022</option>
                                                            {/* Add more options for other years */}
                                                        </Select>
                                                        <FormErrorMessage>{errors.year}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </div>

                                        <Field name="cvv">
                                            {({ field }) => (
                                                <FormControl mb={4} isInvalid={touched.cvv && errors.cvv}>
                                                    <FormLabel htmlFor="cvv"> Cvv </FormLabel>
                                                    <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="cvv" placeholder="Cvv" />
                                                    <FormErrorMessage>{errors.cvv}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="country">
                                            {({ field }) => (
                                                <FormControl mb={4} isInvalid={touched.country && errors.country}>
                                                    <FormLabel htmlFor="country"> Country or Region </FormLabel>
                                                    <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="country" placeholder="Enter country or region" />
                                                    <FormErrorMessage>{errors.country}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>


                                        <Checkbox
                                            size='md'
                                            colorScheme='green'
                                            isChecked={isChecked}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!isChecked}
                                        >
                                            By placing an order, you agree to our terms of service and privacy policy.
                                        </Checkbox>



                                        <Flex mt={4} justifyContent="center">

                                            <DefaultButton
                                                text={"Subscribe"}
                                                as="button"
                                                type="submit"
                                                color="white"
                                                fontWeight="bold"
                                                borderRadius={10}
                                                isLoading={isSubmitting}
                                                mb="2"
                                                borderColor={"white"}
                                                borderWidth={1}
                                                backgroundColor={"transparent"}
                                                bgGradient={"linear(to-r, #D10781, #7D2FAD)"}
                                                width="70%"
                                                fontSize={{ base: "12", lg: "14" }}
                                            />
                                        </Flex>

                                    </form>
                                )}
                            </Formik>


                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default PaymentModal;
