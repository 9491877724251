import React, { useState } from 'react';

const FeedbackButton = () => {

  return (
      <style>
        {`
        .feedback-button {
            color: white;
            background-image: linear-gradient(90deg, #D10781 0.01%, #7D2FAD 99.99%);
            font-size: 20px;
            padding: 23px 38px;
            border-radius: 10px;
            margin-left: 20px;
            position: relative;
            overflow: hidden;
            transition: 0.4s all;
            font-weight: 700;
        }

        .feedback-button:before {
          content: "";
          position: absolute;
          top: 0;
          left: -80px;
          width: 100px;
          height: 50px;
          transform: skew(-25deg);
          animation: main_anim 3s infinite ease-in-out;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.2) 100%
          );
        }

        @keyframes main_anim {
          0% {
            left: -120px;
            opacity: 0;
          }
          10% {
            opacity: 1;
          }
          100% {
            left: 160px;
            opacity: 0;
          }
        }
        `}
      </style>
  );
}

export default FeedbackButton;
