import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import Select from "react-select";

const DropDownInput = ({ label, Data = [], value, onChange, ...props }) => {

  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: 10,
      height: 40,
      color: "#7D8398"
    }),
  };

  return (
    <Box w={"100%"}>
      {label && (
        <Text fontWeight={"500"} fontSize={20} color={"gray"} mb="2">
          {label}
        </Text>
      )}
      <Select
        options={Data}
        value={selectedValue}
        placeholder={"Select"}
        getOptionLabel={(option) => (

          <div style={{ display: "flex", alignItems: "center" }} >
            <div style={{ borderRadius: "5px", overflow: "hidden" }}>
              {option.icon}
            </div>
            <span style={{ marginLeft: "10px" }}>{option.label}</span>
          </div>
        )}
        style={{
          backgroundColor: "white",
          borderRadius: 10,
          height: 50,
        }}
        styles={colourStyles}
        {...props}
        onChange={(option) => {
          setSelectedValue(option);
          onChange(option);
        }}
      />

    </Box>
  );
};

export default DropDownInput;
