/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Icon,
    Center,
    Badge,
    Switch,
} from "@chakra-ui/react";
import { DashBoardLayout, SelectUpdateCard } from "../../components";
import {
    Difference,
} from "../../components/icons";

const monthlyList = [
    "Upgraded 512 px Image Resolution for Generation Tool (16 Style)",
    "500 Credit For a Mounthly Text Generation Tool",
    "Text Editor",
    "Customize Text Generation",
    "General Social Media Templates",
    "20+ Languages",
    "Chat by Orbina",
    "Credit roll over from month to month "
]

const freeList = [
    "5 Image Generation ",
    "10 Text Generation",
    "20+ Languages",
    "Chat by Orbina"
]

const entList = [
    "Api Access",
    "Private Company Infobase",
    "Personal Account Manager",
    "Dedicated Support & Training",
    "For Teams of Up To 5 Users"
]


const Premium = () => {

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        document.title = 'Premium | Orbina AI ';
    }, [])


    const handleSwitchChange = () => {
        setIsChecked((prevChecked) => !prevChecked);
    };


    return (
        <DashBoardLayout title="Premium">
            <Box
                display={"flex"}
                gap="5"
                flexDirection={{ base: "column", md: "row" }}
                mt={2}
            >
                <Box w={{ base: "100%" }}
                    backgroundColor="blur"
                    borderRadius={10}
                    p="3"
                >

                    <Box display={"flex"} alignItems="center" p="2">
                        <Center flex="1" pt={2}>
                            <Text color={"white"} fontWeight={"600"} fontSize="24">
                                Pay Monthly &nbsp;
                            </Text>

                            <Switch
                                isChecked={isChecked}
                                onChange={handleSwitchChange}
                                style={{
                                    backgroundColor: "purple",
                                    borderRadius: "9999px",
                                    padding: "0px",
                                }}
                                size="lg"
                                colorScheme="purple"
                            />


                            <Text color={"white"} fontWeight={"600"} fontSize="24">
                                &nbsp; Pay Yearly &nbsp;
                            </Text>

                            <Badge
                                borderRadius="15px"
                                p={2}
                                fontSize="14px"

                                style={{
                                    backgroundColor: "#7D2FAD",
                                    fontWeight: "600",
                                    color: "white", // Optional, to set the text color
                                }}
                            >
                                Save 25%!
                            </Badge>
                        </Center>
                    </Box>

                    <Box
                        display="grid"
                        gridGap="4"
                        mt={2}
                        gridTemplateColumns={{
                            base: "1fr",
                            md: "1fr",
                            xl: "1fr 1fr 1fr",
                        }}
                        px="12"

                    >
                        <SelectUpdateCard
                            icon={<Icon as={Difference} boxSize={14} color="gray3" />}
                            title="Free"
                            infoTitle="$0"
                            infoTitle2="/Monthly"
                            listItems={freeList}
                            isWhite={false}
                            butonLabel="Sign Up For Free"
                        />
                        <SelectUpdateCard
                            icon={<Icon as={Difference} boxSize={14} color="gray3" />}
                            title="Monthly"
                            infoTitle={isChecked ? "$19,99" : "$27,99"}
                            infoTitle2="/Monthly"
                            listItems={monthlyList}
                            isWhite={true}
                            butonLabel="Select"
                        />
                        <SelectUpdateCard
                            icon={<Icon as={Difference} boxSize={14} color="gray3" />}
                            title="Enterprise"
                            infoTitle="Contact us"
                            infoTitle2=""
                            listItems={entList}
                            isWhite={false}
                            butonLabel="Select"
                        />
                    </Box>

                </Box>
            </Box>
        </DashBoardLayout>
    );
};

export default Premium;
