/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const NavItem = ({
  active = null,
  inactive = null,
  isActive = false,
  to = "#",
  isHover = false,
  text = "",
}) => {

  const [showText, setShowText] = useState(isHover);

  useEffect(() => {
    let timeoutId;

    if (!isHover) {
      timeoutId = setTimeout(() => {
        setShowText(false);
      }, 70);
    } else {
      setShowText(true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isHover]);

  return (
    <Box
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
      mt={1}
      h="14"
      pl={isHover ? "3" : "0"}
      borderRadius={6}
      backgroundColor={isActive && isHover && "#EDEEFD"}
      position="relative"
    >
      <Link
        _hover={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
        as={RouterLink}
        to={to}
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent={showText ? "flex-start" : "center"}
        gap={isHover ? "3" : "0"}
        style={{
          width: "100%",
          transition: "padding-left 0.3s, background-color 0.3s",
        }}
      >
        {isActive ? active : inactive}
        <Text
          color={isActive ? "pink" : "black"}
          fontWeight={isActive ? "800" : "500"}
          fontSize={16}
          style={{
            opacity: showText ? 1 : 0,
            transition: "opacity 0.55s ease-out",
            marginLeft: isHover ? "5px" : "0",
          }}
        >
          {showText ? text : ""}
        </Text>
      </Link>
    </Box>

  );
};

export default NavItem;
