import React from "react";
import { Box, Text } from "@chakra-ui/react";

const CardItem = ({ title, label }) => {
  return (
    <Box
      minW={"100%"}
      pos="relative"
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection="column"
        justifyContent="center"
        backgroundColor={"#D10781"}
        borderRadius={20}
        pl="6"
        minH={90}
        w={"75%"}
        top={0}
        position="absolute"
      >
        <Text fontWeight={"600"} fontSize="30" color={"white"}>
          {title}
        </Text>
        <Text fontWeight={"400"} fontSize="16" color={"white"}>
          {label}
        </Text>
      </Box>
      <Box
        w={"70%"}
        backgroundColor="#E98EC6"
        h={10}
        mt={16}
        borderRadius={20}
      />
    </Box>
  );
};

export default CardItem;
