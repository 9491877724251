import React from "react";

const FaceBookColor = (props) => {
  return (
    <svg
      width="20"
      height="37"
      viewBox="0 0 20 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6761 20.2578L19.7024 13.7427H13.3803V9.50792C13.3803 7.72646 14.2629 5.98571 17.0853 5.98571H20V0.437731C18.3026 0.167296 16.5875 0.0209915 14.8684 0C9.665 0 6.26789 3.12519 6.26789 8.77497V13.7427H0.5V20.2578H6.26789V36.0161H13.3803V20.2578H18.6761Z"
        fill="#337FFF"
      />
    </svg>
  );
};

export default FaceBookColor;
