import React from "react";

const AutoFix = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8333 4.6665L12.2 3.29984L10.8333 2.6665L12.2 2.03317L12.8333 0.666504L13.4667 2.03317L14.8333 2.6665L13.4667 3.29984L12.8333 4.6665ZM5.16667 4.6665L4.53334 3.29984L3.16667 2.6665L4.53334 2.03317L5.16667 0.666504L5.8 2.03317L7.16667 2.6665L5.8 3.29984L5.16667 4.6665ZM12.8333 12.3332L12.2 10.9665L10.8333 10.3332L12.2 9.69984L12.8333 8.33317L13.4667 9.69984L14.8333 10.3332L13.4667 10.9665L12.8333 12.3332ZM2.9 14.4665L1.03334 12.5998C0.900003 12.4665 0.833336 12.3054 0.833336 12.1165C0.833336 11.9276 0.900003 11.7665 1.03334 11.6332L8.46667 4.19984C8.6 4.0665 8.76111 3.99984 8.95 3.99984C9.13889 3.99984 9.3 4.0665 9.43334 4.19984L11.3 6.0665C11.4333 6.19984 11.5 6.36095 11.5 6.54984C11.5 6.73873 11.4333 6.89984 11.3 7.03317L3.86667 14.4665C3.73334 14.5998 3.57222 14.6665 3.38334 14.6665C3.19445 14.6665 3.03334 14.5998 2.9 14.4665ZM3.4 13.0665L8.16667 8.2665L7.23334 7.33317L2.43334 12.0998L3.4 13.0665Z"
        fill="white"
      />
    </svg>
  );
};

export default AutoFix;
