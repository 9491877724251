import React from "react";
import { Button } from "@chakra-ui/react";

const DefaultButton = ({ onClick = () => {}, text, ...props }) => {
  return (
    <Button
      backgroundColor={"white"}
      _active={{
        transform: "scale(0.92)",
      }}
      onClick={onClick}
      {...props}
    >
      {text}
    </Button>
  );
};

export default DefaultButton;
