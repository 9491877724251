/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Text, Image, Icon } from "@chakra-ui/react";
import { MainLayout, DefaultButton, CardItem,CodeBlock } from "../components";
import images from "../constants/images";
import { Squirrel } from "../components/icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../constants/routes";

const Chatbot = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>

      <Text>Chatbot</Text>
      <Box
        display={"flex"}
        flexDirection="column"
        w={{ base: "100%", md: "40%" }}
        mt="2"
        mr={2}
      >
        <Text
          letterSpacing={"0.1em"}
          fontWeight={"700"}
          fontSize={"32"}
          color={"white"}
        >
          Chatbot
        </Text>
        <Text
          fontWeight={"500"}
          fontSize={"28"}
          color={"gray"}
          mt={{ base: "1", md: "1", lg: "9" }}
          letterSpacing={"0.08em"}
        >
          You can ask your questions to the chatbot.
        </Text>
        <DefaultButton
          text={"Try for free"}
          borderColor={"white"}
          borderRadius={10}
          color="white"
          borderWidth={1}
          backgroundColor={"transparent"}
          rightIcon={<Icon as={Squirrel} color="white" />}
          width={"80%"}
          h="12"
          mt={{ base: "5", md: "8", lg: "10" }}
          onClick={() => navigate(routes.login.path)}
        />
      </Box>
    </MainLayout>
  );
};

export default Chatbot;
