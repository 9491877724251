import { instance as axios } from './axiosInstance';

const generatetext = (data) => axios.post('/ai/generatetext', data);

const generateimage = (data) => axios.post('/ai/generateimage', data);

const listsimage = () => axios.get('/ai/listsimage');

const generateSocial = (platformLabel, data) => axios.post(`/ai/generatesocial/${platformLabel}`, data);

const getTexts = () => axios.get(`/ai/liststext`)

const getPrompts = () => axios.get(`ai/promptlibrary`)

export {
    generatetext,
    generateSocial,
    getTexts,
    generateimage,
    listsimage,
    getPrompts
};