/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  Box,
  Text,
  Icon,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  Image,
  Divider,
} from "@chakra-ui/react";
import { NavItem, MenuButton } from ".";
import {
  Notification,
  Menu as Nav,
  Home,
  HomeActive,
  Photo,
  PhotoActive,
  Difference,
  DifferenceActive,
  BookMark,
  Description,
  DescriptionActive,
  Quiz,
  Settings,
  Profile,
  ProfileActive,
  CustomizedGenerations,
  ChatBot,
  ChatbotActive,
  CustomizedGenerationsActive,
  BookMarkActive,
} from "./icons";
import { useLocation } from "react-router-dom";
import { routes } from "../constants/routes";
import { useMediaQuery } from "react-responsive";
import images from "../constants/images";
import { RiStarLine } from "react-icons/ri";

const TopBar = ({ title }) => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      minH={"9vh"}
      w="100%"
      borderRadius={10}
      backgroundColor="white"
      display={"flex"}
      flexDirection="row"
      alignItems={"center"}
    >
      {isMobile && <Icon as={Nav} boxSize={7} ml={5} onClick={onOpen} />}
      {isMobile && (
        <Drawer
          placement={"left"}
          onClose={onClose}
          isOpen={isOpen}
          size={"xs"}
        >
          <DrawerOverlay />
          <DrawerContent maxW={"70%"}>
            <DrawerHeader>
              <Image src={images.logoBlack} w={"70%"} mt={6} mx="auto" />
            </DrawerHeader>
            <DrawerBody>
              <Box display="flex" flexDirection={"column"} w={"100%"} mt={8}>
                <Text color="#666666" fontWeight={"600"} fontSize="16">
                  MAIN MENU
                </Text>
                <NavItem
                  to={routes.dashboardHome.path}
                  isActive={location.pathname === routes.dashboardHome.path}
                  active={<Icon as={HomeActive} boxSize={"6"} />}
                  inactive={<Icon as={Home} boxSize={"6"} />}
                  isHover={true}
                  text="Home"
                />
                <NavItem
                  to={routes.imageGenerationDashBoard.path}
                  isActive={
                    location.pathname === routes.imageGenerationDashBoard.path
                  }
                  active={<Icon as={PhotoActive} boxSize={"6"} />}
                  inactive={<Icon as={Photo} color="black" boxSize={"6"} />}
                  isHover={true}
                  text="Image Generation"
                />
                <NavItem
                  to={routes.textArticleDashBoard.path}
                  isActive={
                    location.pathname === routes.textArticleDashBoard.path
                  }
                  active={<Icon as={DifferenceActive} boxSize={"6"} />}
                  inactive={
                    <Icon as={Difference} color="black" boxSize={"6"} />
                  }
                  isHover={true}
                  text="Text & Article"
                />
                <NavItem
                  to={routes.customizedGenerations.path}
                  isActive={
                    location.pathname === routes.customizedGenerations.path
                  }
                  active={
                    <Icon as={CustomizedGenerationsActive} boxSize={"6"} />
                  }
                  inactive={
                    <Icon
                      as={CustomizedGenerations}
                      color="black"
                      boxSize={"6"}
                    />
                  }
                  isHover={true}
                  text="Customized Generations"
                />
                <NavItem
                  to={routes.saved.path}
                  isActive={location.pathname === routes.saved.path}
                  active={<Icon as={BookMarkActive} boxSize={"6"} />}
                  inactive={<Icon as={BookMark} boxSize={"6"} />}
                  isHover={true}
                  text="Saved"
                />
                <NavItem
                  to={routes.templatesDashBoard.path}
                  isActive={
                    location.pathname === routes.templatesDashBoard.path
                  }
                  active={<Icon as={DescriptionActive} boxSize={"6"} />}
                  inactive={
                    <Icon as={Description} color="black" boxSize={"6"} />
                  }
                  isHover={true}
                  text="Templates"
                />
                <NavItem
                  to={routes.chatBotDashboard.path}
                  isActive={location.pathname === routes.chatBotDashboard.path}
                  active={
                    <Icon as={ChatbotActive} color="black" boxSize={"6"} />
                  }
                  inactive={<Icon as={ChatBot} color="black" boxSize={"6"} />}
                  isHover={true}
                  text="Chatbot"
                />

                {/* <NavItem
                  to={routes.premium.path}
                  isActive={location.pathname === routes.premium.path}
                  active={<Icon as={RiStarLine} color="pink" boxSize={"6"} />}
                  inactive={<Icon as={RiStarLine} color="black" boxSize={"6"} />}
                  isHover={true}
                  text="Premium"
                /> */}

                <Divider
                  color={"#E0DFF0"}
                  w="90%"
                  alignSelf={"center"}
                  my="5"
                />
                <Text color="#666666" fontWeight={"600"} fontSize="16" mt="5">
                  PREFERENCES
                </Text>
                <NavItem
                  //to={routes.textArticle.path}
                  //isActive={location.pathname === routes.textArticle.path}
                  active={<Icon as={Quiz} boxSize={"6"} />}
                  inactive={<Icon as={Quiz} boxSize={"6"} />}
                  isHover={true}
                  text="Settings"
                />
                <NavItem
                  //to={routes.textArticle.path}
                  //isActive={location.pathname === routes.textArticle.path}
                  active={
                    <Icon as={Settings} boxSize={{ base: "6", sm: "8" }} />
                  }
                  inactive={
                    <Icon as={Settings} boxSize={{ base: "6", sm: "8" }} />
                  }
                  isHover={true}
                  text="Help"
                />
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      <Text mx={"auto"} fontWeight={"700"} fontSize="18" >
        {title}
      </Text>
      <Box display={"flex"} gap="6" mr={"5"}>
        {/* <IconButton
          colorScheme="white"
          icon={<Icon as={Notification} boxSize={5} />}
        /> */}
        <MenuButton icon={Profile} activeIcon={ProfileActive} />
      </Box>
    </Box>
  );
};

export default TopBar;
