import React from "react";
import { Box, Text, Image, Center } from "@chakra-ui/react";
import images from "../../constants/images";
import { DefaultButton } from "../";

const DashboardCard = ({
    onClick = () => { },
    icon,
    title = "",
    text = "",
}) => {
    return (
        <Box
            w={"100%"}
            p="5"
            borderRadius={10}
            backgroundColor="white"
            boxShadow={"lg"}
            shadow="#AF97E9"
            cursor={"pointer"}
            onClick={onClick}
        >
            <Box display={"flex"} justifyContent={"space-between"} alignItems="center" >
                <Box display={"flex"} alignItems="center" gap={5}>
                    {icon}
                    <Text fontWeight={"700"} fontSize="20">
                        {title}
                    </Text>
                </Box>

            </Box>
            <Text fontWeight={"500"} fontSize="16" mt={4} mb={5}>
                {text}
            </Text>


            <Box display={"flex"} justifyContent="space-between" p="2">
                    <DefaultButton
                        text={"Set as default"}
                        borderColor={"purple"}
                        borderRadius={10}
                        color="purple"
                        borderWidth={0}
                        mb="1"
                        backgroundColor={"transparent"}
                        boxShadow={"xl"}
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                        alignSelf="flex-end"
                        mr="7"
                        //onClick={() => deleteChatx()}
                        width="150px"
                        fontSize="20"
                    />


                    <DefaultButton
                        text={"Delete"}
                        borderColor={"purple"}
                        borderRadius={10}
                        color="purple"
                        borderWidth={0}
                        mb="1"
                        backgroundColor={"transparent"}
                        boxShadow={"xl"}
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                        alignSelf="flex-end"
                        mr="7"
                        //onClick={() => deleteChatx()}
                        width="100px"
                        fontSize="20"
                    />
            </Box>


        </Box>
    );
};

export default DashboardCard;
