import React from "react";
import { InputGroup, Text, Input as CInput } from "@chakra-ui/react";

const AuthInput = ({ label, ...props }) => {
  return (
    <>
      {label && (
        <Text fontSize={16} fontWeight={"400"} mb="-2" color="#D9D9D9">
          {label}
        </Text>
      )}
      <InputGroup
        my={"3"}
        backgroundColor={"white"}
        size={"lg"}
        borderRadius="10"
      >
        <CInput borderWidth={0} {...props} />
      </InputGroup>
    </>
  );
};

export default AuthInput;
