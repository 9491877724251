const steps = [
    //lang-style
    {
      id: "intro",
      attachTo: { element: "#lang-select", on: "bottom" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
      buttons: [
        {
          classes: "btn btn-custom",
          text: "Next",
          action() {
            return this.next();
          },
        },
      ],
      title: "1st Step !",
      text: ["Select Language."],
    },
    
    //artstyle
    {
      id: "intro1",
      attachTo: { element: "#artstyle", on: "top" },
  
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "2st Step !",
      text: "Choose Art Style.",
    },
    //artwork
    {
      id: "intro2",
      attachTo: { element: "#artwork", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "3rd Step",
      text: "Create Artwork.",
    },
    //edit
    {
      id: "intro3",
      attachTo: { element: "#edit", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "4th Step",
      text: "Edit Existing Image.",
    },
    //make
    {
      id: "intro4",
      attachTo: { element: "#make", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Quit !",
          classes: "btn btn-primary",
          action() {
            return this.complete();
          },
        },
      ],
      title: "Final Step !",
      text: "Make Variations or Download.",
    },
  ];
  
  export default steps;