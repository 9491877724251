const images = {
  logo: require("../assets/images/logo.png"),
  logosvg: require("../assets/images/OrbinaLogo.svg"),
  logotitle: require("../assets/images/logotitle.png"),
  colorTitleLogo: require("../assets/images/colorTitleLogo.png"),
  logoBlack: require("../assets/images/logoBlack.png"),
  loginBackground: require("../assets/images/loginBackground.png"),
  loginLogo: require("../assets/images/loginLogo.png"),
  background: require("../assets/images/background.png"),
  filePink: require("../assets/images/filePink.png"),
  space: require("../assets/images/space.png"),
  textGenerated: require("../assets/images/textGenerated.png"),
  background2: require("../assets/images/background2.png"),
  tech: require("../assets/images/tech.png"),
  techBack: require("../assets/images/techBack.png"),
  imageDown: require("../assets/images/imageDown.png"),
  artStyle: require("../assets/images/artStyle.png"),
  artStylePremium: require("../assets/images/artStylePremium.png"),
  loading: require("../assets/images/loading.png"),
  profileAvatar: require("../assets/images/profileAvatar.png"),
  edit: require("../assets/images/edit.png"),
  textEdit: require("../assets/images/textEdit.png"),
  templates: require("../assets/images/templates.png"),
  cat: require("../assets/images/cat.png"),
  facebookAvatar: require("../assets/images/facebookAvatar.png"),
  facebookLike: require("../assets/images/facebookLike.png"),
  facebookHeart: require("../assets/images/facebookHeart.png"),
  medium: require("../assets/images/medium.png"),
  chatbot: require("../assets/images/chatbot.png"),
  konfeti : require("../assets/images/konfeti.png"),

  //image style
  threeDRender: require("../assets/imageStyle/3drender.jpeg"),
  abstract: require("../assets/imageStyle/abstract.jpeg"),
  abstract_2: require("../assets/imageStyle/abstract_2.jpeg"),
  anime: require("../assets/imageStyle/anime.jpeg"),
  artdeco: require("../assets/imageStyle/artdeco.jpeg"),
  cartoon: require("../assets/imageStyle/cartoon.jpeg"),
  illustration: require("../assets/imageStyle/illustration.jpeg"),
  photography: require("../assets/imageStyle/photography.jpeg"),
  pixelart: require("../assets/imageStyle/pixelart.jpeg"),
  popart : require("../assets/imageStyle/popart.jpeg"),
  realistic : require("../assets/imageStyle/realistic.jpeg"),
  realisticv2 : require("../assets/imageStyle/realisticv2.png"),
  retro : require("../assets/imageStyle/retro.jpeg"),
  sketch : require("../assets/imageStyle/sketch.jpeg"),
  vapowave : require("../assets/imageStyle/vapowave.jpeg"),
  digitalArt :  require("../assets/imageStyle/digitalArt.jpg"),
  origami :  require("../assets/imageStyle/origami.jpg"),
};

export default images;
