import React from "react";

const Linkedn = (props) => {
  return (
    <svg
    width="20"
    height="20"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.01914 0C0.904346 0 0 0.876812 0 1.95703V25.364C0 26.4443 0.904453 27.3203 2.01914 27.3203H25.3012C26.4164 27.3203 27.3203 26.4442 27.3203 25.3636V1.95703C27.3203 0.876812 26.4164 0 25.3012 0H2.01914ZM8.30217 10.5639V22.8636H4.21394V10.5639H8.30217ZM8.57164 6.76007C8.57164 7.94039 7.68426 8.88486 6.25891 8.88486L6.23212 8.88476C4.86002 8.88476 3.97296 7.94028 3.97296 6.75996C3.97296 5.55285 4.88681 4.63474 6.28601 4.63474C7.68426 4.63474 8.54496 5.55285 8.57164 6.76007ZM14.653 22.8636H10.5651C10.5651 22.8636 10.6186 11.7182 10.5653 10.5643H14.6533V12.3053C15.1967 11.4674 16.1693 10.2755 18.3378 10.2755C21.0275 10.2755 23.0441 12.0335 23.0441 15.8113V22.8636H18.9562V16.2843C18.9562 14.6307 18.3643 13.5029 16.8853 13.5029C15.7557 13.5029 15.0831 14.2634 14.7877 14.9982C14.6797 15.2604 14.653 15.6285 14.653 15.9956V22.8636Z"
        fill="#006699"
      />
    </svg>
  );
};

export default Linkedn;
