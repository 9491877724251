const steps = [
    {
      id: "intro",
      attachTo: { element: "#logo-tour", on: "bottom" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
      buttons: [
        {
          classes: "btn btn-custom",
          text: "Next",
          action() {
            return this.next();
          },
        },
      ],
      title: "Profile Picture !",
      text: ["You Can Upload New Picture."],
    },
  
    {
      id: "intro1",
      attachTo: { element: "#name", on: "bottom" },
  
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "Your Name !",
      text: "You Can Change Your Name.",
    },
    {
      id: "intro2",
      attachTo: { element: "#mail", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "Your e-Mail Address !",
      text: "You can Change Your e-Mail Address.",
    },
    {
      id: "intro3",
      attachTo: { element: "#role", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "Your Role !",
      text: "Select Your Role.",
    },
    {
      id: "intro4",
      attachTo: { element: "#usage", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "Usage Credit !",
      text: "You Can See Your Daily Credit In Detail Here.",
    },
    {
      id: "intro5",
      attachTo: { element: "#brand", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          classes: "btn btn-custom",
          action() {
            return this.next();
          },
        },
      ],
      title: "Brand Information !",
      text: "Select The Brand Information.",
    },
    {
      id: "intro6",
      attachTo: { element: "#change", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Quit !",
          classes: "btn btn-primary",
          action() {
            return this.complete();
          },
        },
      ],
      title: "Final Step !",
      text: "Save Your Changes.",
    },
  ];
  
  export default steps;