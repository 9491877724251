import React, { useState, useEffect, useContext } from "react";
import { Box, Icon, useDisclosure, Textarea, Text } from "@chakra-ui/react";
import {
  DashBoardLayout,
  DefaultButton,
  InputWithTitle,
  DropDownInput,
  LoadingModal,
  FeedBackModal,
  SquirrelCard,
  TextEditor,
  DailyLimit
} from "../../components";
import { Pencil, AutoFix, Download } from "../../components/icons";
import { ToneData, Languages, OrbinaTextModels } from "../../constants/data";
import { toast } from "react-toastify";
import { UserContext } from "../../context/user";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { generatetext } from "../../api/ai";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import newSteps from './steps/text';
import { stepsUpdate, dashboradInfo } from "../../api/user";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: "smooth", block: "center" },
  },
  useModalOverlay: true
};

const Autton = () => {
  const tour = useContext(ShepherdTourContext);
  const [state, dispatch] = useContext(UserContext);

  const handleStepCompletion = async (step) => {
    dispatch({ type: 'UPDATE_STEP', payload: { step, value: true } });

    try {
      const response = await stepsUpdate({ stepComplete4: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!state.stepComplete4 && !tour.isActive()) {
      tour.start();
    }

    tour.on('complete', () => {
      handleStepCompletion('stepComplete4');
    });

  }, [tour]);

  return (<> </>);
}

const TextArticleDashBoard = () => {
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [dropDownLang, setDropDownLang] = useState(null);
  const [dropDownTone, setDropDowntone] = useState(null);
  const [orbinaModel, setOrbinaModel] = useState(null);
  const [create, setCreate] = useState("");
  const [notReplacedText, setnotReplacedText] = useState("");
  const [createVariations, setCreateVariations] = useState(false);
  const loadingVideoModal = useDisclosure();
  const feedBackModal = useDisclosure();
  const dailyLimit = useDisclosure();

  useEffect(() => {
    document.title = 'Text & Article | Orbina AI ';
  }, [])


  const notify = (data) =>
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const CreateText = async () => {
    if (!dropDownLang) {
      notify("Please Select A Language.");
      return 0;
    }

    if (!dropDownTone) {
      notify("Please Select A Tone.");
      return 0;
    }

    if (!orbinaModel) {
      notify("Please Select An Orbina Model.");
      toast.error();
      return 0;
    }

    if (!text) {
      notify("Please Describe Your Topic.");
      return 0;
    }

    if (!text2) {
      notify("Please Describe Primary Keywords.");
      return 0;
    }

    loadingVideoModal.onOpen();
    try {
      const obj = {
        topic: text,
        keyword: text2,
        textTone: dropDownTone?.label,
        textLanguage: dropDownLang?.value,
        textModel: orbinaModel?.value,
      };

      const response = await generatetext(obj);
      setnotReplacedText(response?.data?.text);
      setCreate(response?.data?.text.replace(/\n/g, "<br/>"));


      if (response?.response?.data?.message == "Your daily limit is reached") {
        dailyLimit.onOpen();
      }
      else {
        const responseFeedBack = await dashboradInfo();
        if (responseFeedBack?.data?.countText % 6 == 1) {
          setTimeout(() => {
            feedBackModal.onOpen();
          }, 30000);
        }
      }


    } catch (error) {
      console.log(error);
    } finally {
      loadingVideoModal.onClose();
    }
  };

  const newArticlePage = () => {
    setCreate("");
    setText("");
    setText2("");
    setDropDownLang(null);
    setDropDowntone(null);
    setOrbinaModel(null);
  };

  function downloadTxtFile() {
    if (notReplacedText) {
      const myHTMLString = notReplacedText;
      const parser = new DOMParser();
      const doc = parser.parseFromString(myHTMLString, "text/html");
      const myTextString = doc.body.textContent;
      const element = document.createElement("a");
      const file = new Blob([myTextString], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = "Orbina_Text.txt";
      document.body.appendChild(element);
      element.click();
    }
  }

  const MakeVariations = () => {
    setCreateVariations(true);
    loadingVideoModal.onOpen();
  };

  return (
    <DashBoardLayout title="Text & Article">
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <Autton />


        <Box
          display={"flex"}
          flexDirection={{ base: "column", md: "row", lg: "row" }}
          justifyContent="space-between"
          gap="5"
          mt={"6"}
        >
          <Box
            display={"flex"}
            flex={1}
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            gap={{ md: "2", lg: "16" }}
          >
            <Box
              w={{ base: "100%", md: "45%", lg: "50%" }}
              display="flex"
              flexDirection={"column"}
            >
              <Box display="grid"

                gap={4}
                gridTemplateColumns={{
                  base: "1fr ",
                  md: "1fr ",
                  lg: "1fr 1fr ",
                }}
                justifyContent={"space-between"}>
                <Box  >
                  <DropDownInput
                    Data={Languages}
                    id="lang-select"
                    onChange={(val) => setDropDownLang(val)}
                    borderRadius={15}
                    placeholder="Select Language"
                    value={dropDownLang}
                  />
                </Box>
                <Box mb="5" id="model">
                  <DropDownInput
                    Data={OrbinaTextModels}
                    onChange={(val) => setOrbinaModel(val)}
                    borderRadius={15}
                    placeholder="Select AI Model"
                    value={orbinaModel}
                  />
                </Box>
              </Box>
              <Box>

                <Box>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    mt="3"
                  >
                    <Text fontWeight={"500"} fontSize={18} letterSpacing={"-0.04em"} color={"gray"}>
                      Describe your topic*
                    </Text>
                    <Text fontWeight={"500"} fontSize="14" color={"lightGray"}>
                      {text.length}/800
                    </Text>
                  </Box>
                  <Textarea
                    value={text}
                    placeholder="Your topic"
                    id="topic"
                    _placeholder={{ opacity: 0.6 }}
                    backgroundColor={"white"}
                    borderRadius={10}
                    mb="2"
                    mt="1"
                    minH={"20"}
                    maxH={null}
                    onChange={(val) => {
                      if ((text.length < 800) || (text.length == 800 && val?.nativeEvent?.data == null)) { setText(val.target.value) }
                    }
                    }
                  />
                </Box>

                <Box>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    mt="3"
                  >
                    <Text fontWeight={"500"} fontSize={18} letterSpacing={"-0.04em"} color={"gray"}>
                      Primary keywords
                    </Text>
                    <Text fontWeight={"500"} fontSize="14" color={"lightGray"}>
                      {text2.length}/800
                    </Text>
                  </Box>
                  <Textarea
                    placeholder="Your keywords"
                    id="keywords"
                    _placeholder={{ opacity: 0.6 }}
                    backgroundColor={"white"}
                    borderRadius={10}
                    mb="2"
                    mt="1"
                    minH={"20"}
                    maxH={null}
                    onChange={(val) => {
                      if ((text2.length < 800) || (text2.length == 800 && val?.nativeEvent?.data == null)) { setText2(val.target.value) }
                    }
                    }
                    value={text2}
                  />
                </Box>




                <Text fontWeight={"500"} fontSize={18} mt={1} mb={1} letterSpacing={"-0.04em"} color={"gray"}>
                  Tone*
                </Text>
                <DropDownInput
                  Data={ToneData}
                  id="tome"
                  placeholder="Select tone"
                  onChange={(val) => setDropDowntone(val)}
                  value={dropDownTone}
                />
              </Box>


              <Box id="make" display="grid" justifyContent="center" >
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"center"}
                  gap={{ base: "3", md: "5", lg: "10" }}
                  mt={{ base: "14", md: "10" }}
                >
                  <DefaultButton
                    text={"Create"}
                    rightIcon={<Icon as={Pencil} />}
                    as="button"
                    color="white"
                    fontWeight="bold"
                    borderRadius={10}
                    mb="1"
                    borderWidth={create && 1}
                    borderColor={!create && "white"}
                    backgroundColor={!create && "transparent"}
                    bgGradient={!create && "linear(to-r, #D10781, #7D2FAD)"}
                    boxShadow={create === true && "xl"}
                    width="150px"
                    alignSelf="center"
                    onClick={() => CreateText()}
                  />
                  <DefaultButton
                    text={"Make variations"}
                    borderColor={"white"}
                    borderRadius={10}
                    color="white"
                    borderWidth={!create && 1}
                    mb="1"
                    backgroundColor={"transparent"}
                    bgGradient={create && "linear(to-r, #D10781, #7D2FAD)"}
                    boxShadow={create === true && "xl"}
                    rightIcon={<Icon as={AutoFix} />}
                    width="150px"
                    fontSize="14"
                    onClick={() => CreateText()}
                  />
                </Box>
                <Box display="grid" justifyContent="center">
                  <DefaultButton
                    text={"Download"}
                    borderColor={"white"}
                    borderRadius={10}
                    color="white"
                    mb="2"
                    borderWidth={!create && 1}
                    bgGradient={create && "linear(to-r, #D10781, #7D2FAD)"}
                    backgroundColor={"transparent"}
                    boxShadow={create === true && "xl"}
                    rightIcon={<Icon as={Download} />}
                    width="140px"
                    alignSelf="center"
                    mt="5"
                    fontSize="14"
                    onClick={() => downloadTxtFile()}
                  />
                </Box>
              </Box>

            </Box>
            <Box
              w={{ base: "100%", md: "45%", lg: "50%" }}
              display="flex"
              flexDirection={"column"}
              alignItems={{ lg: "flex-end" }}
              mt={{ base: "4", sm: "0" }}
            >

              {create ? (
                <>
                  <DefaultButton
                    text={"New Article"}
                    borderColor={"white"}
                    borderRadius={10}
                    color="white"
                    borderWidth={1}
                    mb="4"
                    backgroundColor={"transparent"}
                    boxShadow={"xl"}
                    width="150px"
                    fontSize="14"
                    onClick={() => newArticlePage()}
                  />

                  {/* <CKEditor
                    editor={Editor}
                    data={create}
                    // config={ editorConfiguration }
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "500px",
                          editor.editing.view.document.getRoot()
                        );
                        writer.setStyle(
                          "max-width",
                          "550px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  /> */}

                  <div className="AppEditor">
                    <TextEditor initialText={create} />
                  </div>

                </>
              ) : (
                <Box w={"100%"}>
                  <SquirrelCard text="Your text is being generated." />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <LoadingModal
          onClose={loadingVideoModal.onClose}
          isOpen={loadingVideoModal.isOpen}
        />

        <FeedBackModal
          onClose={feedBackModal.onClose}
          isOpen={feedBackModal.isOpen}
          modalType="text"
        />

        <DailyLimit
          onClose={dailyLimit.onClose}
          isOpen={dailyLimit.isOpen}
        />

      </ShepherdTour>
    </DashBoardLayout >
  );
};

export default TextArticleDashBoard;
