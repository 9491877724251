import React from "react";

const Design = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14C0 13.45 0.196 12.979 0.588 12.587C0.979333 12.1957 1.45 12 2 12C2.55 12 3.02067 12.1957 3.412 12.587C3.804 12.979 4 13.45 4 14C4 14.55 3.804 15.021 3.412 15.413C3.02067 15.8043 2.55 16 2 16ZM2 10C1.45 10 0.979333 9.804 0.588 9.412C0.196 9.02067 0 8.55 0 8C0 7.45 0.196 6.979 0.588 6.587C0.979333 6.19567 1.45 6 2 6C2.55 6 3.02067 6.19567 3.412 6.587C3.804 6.979 4 7.45 4 8C4 8.55 3.804 9.02067 3.412 9.412C3.02067 9.804 2.55 10 2 10ZM2 4C1.45 4 0.979333 3.804 0.588 3.412C0.196 3.02067 0 2.55 0 2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0C2.55 0 3.02067 0.196 3.412 0.588C3.804 0.979333 4 1.45 4 2C4 2.55 3.804 3.02067 3.412 3.412C3.02067 3.804 2.55 4 2 4ZM8 10C7.45 10 6.97933 9.804 6.588 9.412C6.196 9.02067 6 8.55 6 8C6 7.45 6.196 6.979 6.588 6.587C6.97933 6.19567 7.45 6 8 6C8.55 6 9.021 6.19567 9.413 6.587C9.80433 6.979 10 7.45 10 8L8 10ZM8 4C7.45 4 6.97933 3.804 6.588 3.412C6.196 3.02067 6 2.55 6 2C6 1.45 6.196 0.979333 6.588 0.588C6.97933 0.196 7.45 0 8 0C8.55 0 9.021 0.196 9.413 0.588C9.80433 0.979333 10 1.45 10 2C10 2.55 9.80433 3.02067 9.413 3.412C9.021 3.804 8.55 4 8 4ZM7 16V13.875L12.3 8.575L14.425 10.7L9.125 16H7ZM14 4C13.45 4 12.979 3.804 12.587 3.412C12.1957 3.02067 12 2.55 12 2C12 1.45 12.1957 0.979333 12.587 0.588C12.979 0.196 13.45 0 14 0C14.55 0 15.021 0.196 15.413 0.588C15.8043 0.979333 16 1.45 16 2C16 2.55 15.8043 3.02067 15.413 3.412C15.021 3.804 14.55 4 14 4ZM15.125 10L13 7.875L13.725 7.15C13.925 6.95 14.1627 6.85 14.438 6.85C14.7127 6.85 14.9417 6.95 15.125 7.15L15.85 7.875C16.05 8.05833 16.15 8.28733 16.15 8.562C16.15 8.83733 16.05 9.075 15.85 9.275L15.125 10Z"
        fill="#613DBB"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default Design;
