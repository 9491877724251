import React from "react";

const SvgHome = (props) => {
  return (
    <svg
      width="31"
      height="26"
      fontSize={30}
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 26V14H0.5L15.5 0.5L21.5 5.9V2H26V9.95L30.5 14H26V26H17V17H14V26H5ZM8 23H11V14H20V23H23V11.3L15.5 4.55L8 11.3V23ZM12.5 11.0375H18.5C18.5 10.2375 18.2 9.581 17.6 9.068C17 8.556 16.3 8.3 15.5 8.3C14.7 8.3 14 8.556 13.4 9.068C12.8 9.581 12.5 10.2375 12.5 11.0375Z"
        fill="black"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default SvgHome;
