import React, { useEffect, useState } from 'react';
import './Loader.css'; // CSS dosyasını içe aktarın
import images from "../constants/images";
const Loader = () => {
  const [loaderSize, setLoaderSize] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const newSize = windowWidth > 400 ? 400 : windowWidth;
      setLoaderSize(newSize);
    };

    handleResize(); // Başlangıçta boyutu ayarla

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="loader-container" >
      <div className="loader"></div>
      <img src={images.loading} alt="Sabit Resim" className="image" />
    </div>
  );
}

export default Loader;
