import React from "react";

const CaseStudies = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 19C1.45 19 0.979333 18.8043 0.588 18.413C0.196 18.021 0 17.55 0 17V6C0 5.45 0.196 4.97933 0.588 4.588C0.979333 4.196 1.45 4 2 4H6V2C6 1.45 6.196 0.979 6.588 0.587C6.97933 0.195667 7.45 0 8 0H12C12.55 0 13.021 0.195667 13.413 0.587C13.8043 0.979 14 1.45 14 2V4H18C18.55 4 19.021 4.196 19.413 4.588C19.8043 4.97933 20 5.45 20 6V17C20 17.55 19.8043 18.021 19.413 18.413C19.021 18.8043 18.55 19 18 19H2ZM2 17H18V6H2V17ZM8 4H12V2H8V4Z"
        fill="#613DBB"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default CaseStudies;
