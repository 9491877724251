import React from "react";

const FaceBook = (props) => {
  return (
    <svg
      width="14"
      height="25"
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6166 14.0616L13.329 9.53929H8.94063V6.59977C8.94063 5.3632 9.5533 4.15489 11.5124 4.15489H13.5356V0.303844C12.3574 0.116126 11.1669 0.0145709 9.97361 0C6.36174 0 4.00369 2.1693 4.00369 6.09101V9.53929H0V14.0616H4.00369V25H8.94063V14.0616H12.6166Z"
        fill="white"
      />
    </svg>
  );
};

export default FaceBook;
