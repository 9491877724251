import React from "react";

const SvgDifference = (props) => (
  <svg
    width="29"
    height="34"
    fontSize={30}
    viewBox="0 0 29 34"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.25 15.5H19.25V12.5H22.25V9.5H19.25V6.5H16.25V9.5H13.25V12.5H16.25V15.5ZM13.25 21.5H22.25V18.5H13.25V21.5ZM9.5 27.5C8.675 27.5 7.969 27.2065 7.382 26.6195C6.794 26.0315 6.5 25.325 6.5 24.5V3.5C6.5 2.675 6.794 1.9685 7.382 1.3805C7.969 0.7935 8.675 0.5 9.5 0.5H20L29 9.5V24.5C29 25.325 28.7065 26.0315 28.1195 26.6195C27.5315 27.2065 26.825 27.5 26 27.5H9.5ZM9.5 24.5H26V11L18.5 3.5H9.5V24.5ZM3.5 33.5C2.675 33.5 1.969 33.2065 1.382 32.6195C0.794 32.0315 0.5 31.325 0.5 30.5V9.5H3.5V30.5H20V33.5H3.5Z"
      fillOpacity="0.8"
    />
  </svg>
);

export default SvgDifference;
