import React from "react";

const MediumWhite = (props) => {
  return (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.7994 8.25C15.7994 12.527 12.2708 16 7.89969 16C3.52853 16 0 12.527 0 8.25C0 3.97296 3.52853 0.5 7.89969 0.5C12.2708 0.5 15.7994 3.97296 15.7994 8.25ZM24.4539 8.25C24.4539 12.2704 22.6809 15.5381 20.5041 15.5381C18.3273 15.5381 16.5542 12.2704 16.5542 8.25C16.5542 4.22958 18.3273 0.961918 20.5041 0.961918C22.6809 0.961918 24.4539 4.21247 24.4539 8.25ZM28 8.25C28 11.8598 27.3856 14.7853 26.6132 14.7853C25.8408 14.7853 25.2263 11.8598 25.2263 8.25C25.2263 4.64018 25.8408 1.71468 26.6132 1.71468C27.3856 1.71468 28 4.64018 28 8.25Z"
        fill="white"
      />
    </svg>
  );
};

export default MediumWhite;
