import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import Fallback from "./Fallback";
import { routes } from "../constants/routes";
import { isSecuredRoute } from "../helpers";
import { UserStore, UserContext } from '../context/user';

const withSplashScreen = (Component) => (props) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [state, dispatch] = useContext(UserContext);

  const token = "";
  const { search: searchParams, pathname: currentRoute } = window.location;

  useEffect(() => {
    if (token) {
      initialize();
    } else {
      setLoading(false);
      const isSecured = isSecuredRoute(currentRoute);
      go(isSecured && !state.isLoggedIn ? routes.login.path : currentRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const go = (path, search = "") => {
    navigate(path + (search || searchParams));
  };

  const next = () => go(currentRoute, searchParams);

  const initialize = async () => {
    try {
      next();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (loading) return <Fallback />;
  else return <Component {...props} />;
};

export default withSplashScreen;
