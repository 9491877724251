import React from "react";

const Send = (props) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
      <path
        d="M1.16661 14.0707L15.7083 7.8374C16.3833 7.54573 16.3833 6.59573 15.7083 6.30407L1.16661 0.0707335C0.616606 -0.170933 0.00827229 0.2374 0.00827229 0.829067L-6.10352e-05 4.67073C-6.10352e-05 5.0874 0.308272 5.44573 0.724939 5.49573L12.4999 7.07073L0.724939 8.6374C0.308272 8.69573 -6.10352e-05 9.05407 -6.10352e-05 9.47073L0.00827229 13.3124C0.00827229 13.9041 0.616606 14.3124 1.16661 14.0707Z"
        fill="#E5E5E5"
      />
    </svg>
  );
};

export default Send;
