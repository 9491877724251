import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './editor.css';

const Editor = ({ placeholder, initialText }) => {
  const [editorHtml, setEditorHtml] = useState('');
  const [theme, setTheme] = useState('snow');
  const editorRef = useRef(null);
  const quillInstanceRef = useRef(null);

  useEffect(() => {
    if (!quillInstanceRef.current) {
      quillInstanceRef.current = new Quill(editorRef.current, {
        theme: theme,
        placeholder: placeholder,
        modules: {
          toolbar: [
            [],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['clean']
          ],
        }
      });

      quillInstanceRef.current.on('text-change', () => {
        setEditorHtml(editorRef.current.firstChild.innerHTML);
      });
    }

    // Text ekleme
    if (initialText && quillInstanceRef.current) {
      const delta = quillInstanceRef.current.clipboard.convert(initialText);
      quillInstanceRef.current.setContents(delta);
    }
  }, [placeholder, theme, initialText]);

  return (
    <div className="editor-container">
      <div ref={editorRef}></div>
    </div>
  );
};

Editor.propTypes = {
  placeholder: PropTypes.string,
  initialText: PropTypes.string,
};

export default Editor;
