import React from "react";

const LinkednWhite = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.51914 0C1.40435 0 0.5 0.876812 0.5 1.95703V25.364C0.5 26.4443 1.40445 27.3203 2.51914 27.3203H25.8012C26.9164 27.3203 27.8203 26.4442 27.8203 25.3636V1.95703C27.8203 0.876812 26.9164 0 25.8012 0H2.51914ZM8.80217 10.5639V22.8636H4.71394V10.5639H8.80217ZM9.07164 6.76007C9.07164 7.94039 8.18426 8.88486 6.75891 8.88486L6.73212 8.88476C5.36002 8.88476 4.47296 7.94028 4.47296 6.75996C4.47296 5.55285 5.38681 4.63474 6.78601 4.63474C8.18426 4.63474 9.04496 5.55285 9.07164 6.76007ZM15.153 22.8636H11.0651C11.0651 22.8636 11.1186 11.7182 11.0653 10.5643H15.1533V12.3053C15.6967 11.4674 16.6693 10.2755 18.8378 10.2755C21.5275 10.2755 23.5441 12.0335 23.5441 15.8113V22.8636H19.4562V16.2843C19.4562 14.6307 18.8643 13.5029 17.3853 13.5029C16.2557 13.5029 15.5831 14.2634 15.2877 14.9982C15.1797 15.2604 15.153 15.6285 15.153 15.9956V22.8636Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkednWhite;
