const steps = [
    //lang-style
    {
        id: "intro",
        attachTo: { element: "#lang-select", on: "bottom" },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: "btn btn-custom",
                text: "Next",
                action() {
                    return this.next();
                },
            },
        ],
        title: "1st Step !",
        text: ["Select Language."],
    },

    //model
    {
        id: "intro1",
        attachTo: { element: "#model", on: "bottom" },

        buttons: [
            {
                text: "Back",
                classes: "btn btn-light",
                action() {
                    return this.back();
                },
            },
            {
                text: "Next",
                classes: "btn btn-custom",
                action() {
                    return this.next();
                },
            },
        ],
        title: "2st Step !",
        text: "Choose AI Model.",
    },
    //topic
    {
        id: "intro2",
        attachTo: { element: "#topic", on: "bottom" },
        buttons: [
            {
                text: "Back",
                classes: "btn btn-light",
                action() {
                    return this.back();
                },
            },
            {
                text: "Next",
                classes: "btn btn-custom",
                action() {
                    return this.next();
                },
            },
        ],
        title: "3rd Step !",
        text: "Describe Your Topic.",
    },
    //keywords
    {
        id: "intro3",
        attachTo: { element: "#keywords", on: "bottom" },
        buttons: [
            {
                text: "Back",
                classes: "btn btn-light",
                action() {
                    return this.back();
                },
            },
            {
                text: "Next",
                classes: "btn btn-custom",
                action() {
                    return this.next();
                },
            },
        ],
        title: "4th Step !",
        text: "Identify Primary Keywords.",
    },
    //tome
    {
        id: "intro4",
        attachTo: { element: "#tome", on: "bottom" },
        buttons: [
            {
                text: "Back",
                classes: "btn btn-light",
                action() {
                    return this.back();
                },
            },
            {
                text: "Next",
                classes: "btn btn-custom",
                action() {
                    return this.next();
                },
            },
        ],
        title: "5th Step !",
        text: "Select Tone.",
    },
    //make
    {
        id: "intro5",
        attachTo: { element: "#make", on: "bottom" },
        buttons: [
            {
                text: "Back",
                classes: "btn btn-light",
                action() {
                    return this.back();
                },
            },
            {
                text: "Quit !",
                classes: "btn btn-primary",
                action() {
                    return this.complete();
                },
            },
        ],
        title: "Final Step !",
        text: "Make Variations or Download.",
    },
];

export default steps;