import React, { useState } from "react";
import {
  Box, Image, Text, Modal, ModalOverlay, ModalContent, ModalBody, Button
} from "@chakra-ui/react";
import images from "../../constants/images";

const SavedCard = ({ image, text = "", image2, image3, image4,isSaved }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedImage(null); // Modal kapandığında seçimi sıfırla
  };

  const handleTextExpandToggle = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const textStyle = isTextExpanded ? {} : {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1, // Bu, metni sadece 1 satıra sınırlar
    WebkitBoxOrient: 'vertical',
    cursor: 'pointer',
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      p="2"
      borderWidth={1}
      borderRadius={10}
      borderColor="gray3"
      gap="2"
      maxW="100%"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Image src={images.colorTitleLogo} w="30%" />
      </Box>

      {/* Resimlerin görüntülenmesi */}
      <Box
        display="grid"
        gridGap="4"
        mt={2}
        gridTemplateColumns={{
          base: "1fr",
          md: "repeat(" + (image2 ? 2 : 1) + ", 1fr)", // Eğer image2 varsa 2 kolon kullan
          lg: "repeat(" + (image2 ? 2 : 1) + ", 1fr)",
          xl: "repeat(" + (image2 ? 2 : 1) + ", 1fr)",
        }}
        mb={2}
      >
        {/* Resimlerin tıklanabilir olması ve modalı açması */}
        {[image, image2, image3, image4].map((img, index) => {
          return img ? (
            <Image key={index} src={img} w="100%" borderRadius={10} cursor="zoom-in"
              onClick={() => handleOpenModal(img)} />
          ) : null;
        })}
      </Box>

      {image2 && <Box onClick={handleTextExpandToggle}>
        <Text
          fontWeight="500"
          fontSize="13"
          color="white"
          style={textStyle} // stil burada kullanılıyor
        >
          {text}
        </Text>
        {/* Metni genişletmek/daraltmak için buton */}
        <Button size="sm" variant="link" color={"white"}>
          {isTextExpanded ? 'Show Less' : 'Show More'}
        </Button>
      </Box>
      }
      {/* Metin alanı ve genişletme/daraltma özelliği */}

      {isSaved && !image2 &&
      <Box onClick={handleTextExpandToggle}>
      <Text
        fontWeight="500"
        fontSize="13"
        color="white"
        style={textStyle} // stil burada kullanılıyor
      >
        {text}
      </Text>
      {/* Metni genişletmek/daraltmak için buton */}
      <Button size="sm" variant="link" color={"white"}>
          {isTextExpanded ? 'Show Less' : 'Show More'}
        </Button>
    </Box>
      
      }

      {/* Resim için modal */}
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl" motionPreset="scale">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <Image src={selectedImage} w="100%" h="100%" objectFit="contain" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SavedCard;
