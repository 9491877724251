import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import images from "../../constants/images";

const SquirrelCard = ({ text = "" }) => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems="center"
      justifyContent={"center"}
      backgroundColor={"white"}
      borderRadius={10}
      h={{ base: "400", md: "500" }}
    >
      <Image src={images.loading} boxSize="60" />
      <Text textAlign={"center"} fontWeight={"500"} fontSize="28" color="black" mt="4">
        {text}
      </Text>
    </Box>
  );
};

export default SquirrelCard;
