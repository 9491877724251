import React from "react";

const SvgPhoto = props => (
    <svg
      width="27"
      height="28"
      fontSize={27}
      viewBox="0 0 27 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 27.5C2.175 27.5 1.4685 27.2065 0.8805 26.6195C0.2935 26.0315 0 25.325 0 24.5V3.5C0 2.675 0.2935 1.9685 0.8805 1.3805C1.4685 0.7935 2.175 0.5 3 0.5H24C24.825 0.5 25.5315 0.7935 26.1195 1.3805C26.7065 1.9685 27 2.675 27 3.5V24.5C27 25.325 26.7065 26.0315 26.1195 26.6195C25.5315 27.2065 24.825 27.5 24 27.5H3ZM3 24.5H24V3.5H3V24.5ZM4.5 21.5H22.5L16.875 14L12.375 20L9 15.5L4.5 21.5Z"
        fillOpacity="0.8"
      />
    </svg>
  );

export default SvgPhoto;
