/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Icon,
    Button,
    Divider,
    useBoolean,
    Center,
    useDisclosure,
    Badge,
    Switch,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useBreakpointValue,
    FormControl,
    Textarea,
    Grid,
    FormErrorMessage,
    Input,
    FormLabel,
    Flex
} from "@chakra-ui/react";
import { DashBoardLayout, DashboardCard, CreditCard, SelectUpdateCard, DefaultButton } from "../../components";
import {
    Difference,
} from "../../components/icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { dashboradInfo, userUsage } from "../../api/user";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from "use-image";
import { Formik, Field } from 'formik';

const initialValues = {
    name: '',
    countryName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    state: '',
    description: '',
};

const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = 'Name field is required.';
    }

    if (!values.countryName) {
        errors.countryName = 'Country field is required.';
    }

    if (!values.addressLine1) {
        errors.addressLine1 = 'Address Line 1 field is required.';
    }

    if (!values.city) {
        errors.city = 'City field is required.';
    }

    if (!values.postalCode) {
        errors.postalCode = 'Postal Code field is required.';
    }

    if (!values.state) {
        errors.state = 'State field is required.';
    }

    if (!values.description) {
        errors.description = 'Description field is required.';
    }

    return errors;
};

const customInputStyle = {
    borderRadius: '10px',
    border: '1px solid var(--orb-gray-5, rgba(125, 131, 152, 0.30))',
    background: 'var(--orb-white, #FFF)',
    boxShadow: '0px 3px 6px 0px rgba(18, 18, 18, 0.03)',
};

const Premium = () => {
    const modalWidth = useBreakpointValue({ base: '100%', md: '55%' });

    const [isOpen, setIsOpen] = useState(false);


    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        document.title = 'Payment Methods | Orbina AI ';
    }, [])


    const handleSubmit = (values, actions) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
        }, 1000);
    };


    return (
        <DashBoardLayout title="Payment Methods">
            <Box
                display={"flex"}
                gap="5"
                flexDirection={{ base: "column", md: "row" }}
                mt={2}
            >
                <Box w={{ base: "100%" }}
                    backgroundColor="blur"
                    borderRadius={10}
                    p="3"
                >


                    <Box display={"flex"} justifyContent="space-between" p="2">
                        <Text color={"white"} fontWeight={"600"} fontSize="24">
                            Payment Methods &nbsp;
                        </Text>

                        <DefaultButton
                            text={"Add Payment Method"}
                            borderColor={"white"}
                            borderRadius={10}
                            color="white"
                            borderWidth={1}
                            mb="1"
                            backgroundColor={"transparent"}
                            boxShadow={"xl"}
                            display="flex"
                            alignItems="center"
                            justifyContent={"center"}
                            alignSelf="flex-end"
                            mr="7"
                            onClick={() => { handleOpenModal(); }}
                            width="200px"
                            fontSize="14"
                        />


                    </Box>

                    <Box
                        display="grid"
                        gridGap="4"
                        mt={2}
                        gridTemplateColumns={{
                            base: "1fr",
                            md: "1fr",
                            xl: "1fr 1fr 1fr",
                        }}
                        px="12"

                    >

                        < CreditCard
                            title="***2458"
                            text="Expires11/2030"
                            icon={<FaCcMastercard color="purple" fontSize="70px" />}
                        />

                        < CreditCard
                            title="***2458"
                            text="Expires11/2030"
                            icon={<FaCcVisa color="purple" fontSize="70px" />}
                        />

                        < CreditCard
                            title="***2458"
                            text="Expires11/2030"
                            icon={<FaCcVisa color="purple" fontSize="70px" />}
                        />


                    </Box>



                </Box>

            </Box>


            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent maxW={modalWidth}>
                    <ModalHeader>
                        <h1 style={{ fontSize: "36px" }} >
                            Add payment method
                        </h1>
                    </ModalHeader>
                    <ModalBody>

                        <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
                            {({ handleSubmit, touched, errors, isSubmitting }) => (
                                <form onSubmit={handleSubmit}>

                                    <Field name="description">
                                        {({ field }) => (
                                            <FormControl mb={4} isInvalid={touched.description && errors.description}>
                                                <FormLabel htmlFor="description">Card information</FormLabel>
                                                <Input
                                                    style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }}
                                                    {...field}
                                                    id="description"
                                                    placeholder="Number on the card" />
                                                <FormErrorMessage>{errors.description}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="name">
                                        {({ field }) => (
                                            <FormControl mb={4} isInvalid={touched.name && errors.name}>
                                                <FormLabel htmlFor="name">Name on card</FormLabel>
                                                <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="name" placeholder="Name" />
                                                <FormErrorMessage>{errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="countryName">
                                        {({ field }) => (
                                            <FormControl mb={4} isInvalid={touched.countryName && errors.countryName}>
                                                <FormLabel htmlFor="countryName">Billing adress</FormLabel>
                                                <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="countryName" placeholder="Country" />
                                                <FormErrorMessage>{errors.countryName}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="addressLine1">
                                        {({ field }) => (
                                            <FormControl mb={4} isInvalid={touched.addressLine1 && errors.addressLine1}>
                                                <FormLabel htmlFor=""></FormLabel>
                                                <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="addressLine1" placeholder="Address line 1" />
                                                <FormErrorMessage>{errors.addressLine1}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="addressLine2">
                                        {({ field }) => (
                                            <FormControl mb={4} isInvalid={touched.addressLine2 && errors.addressLine2}>
                                                <FormLabel htmlFor=""></FormLabel>
                                                <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="addressLine2" placeholder="Address line 2" />
                                                <FormErrorMessage>{errors.addressLine2}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <div style={{ display: 'flex', gap: '20px' }}>
                                        <Field name="city">
                                            {({ field }) => (
                                                <FormControl mb={4} isInvalid={touched.city && errors.city}>
                                                    <FormLabel htmlFor=""></FormLabel>
                                                    <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="city" placeholder="City" />
                                                    <FormErrorMessage>{errors.city}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="postalCode">
                                            {({ field }) => (
                                                <FormControl mb={4} isInvalid={touched.postalCode && errors.postalCode}>
                                                    <FormLabel htmlFor=""></FormLabel>
                                                    <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="postalCode" placeholder="Postal code" />
                                                    <FormErrorMessage>{errors.postalCode}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </div>
                                    <Field name="state">
                                        {({ field }) => (
                                            <FormControl mb={4} isInvalid={touched.state && errors.state}>
                                                <FormLabel htmlFor=""></FormLabel>
                                                <Input style={{ ...customInputStyle, color: field.value ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)" }} {...field} id="state" placeholder="State, country, province or region" />
                                                <FormErrorMessage>{errors.state}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>


                                    <Flex mt={4} justifyContent="flex-end">

                                        <DefaultButton
                                            text={"Submit"}
                                            as="button"
                                            type="submit"
                                            color="white"
                                            fontWeight="bold"
                                            borderRadius={10}
                                            isLoading={isSubmitting}
                                            mb="2"
                                            borderColor={"white"}
                                            borderWidth={1}
                                            backgroundColor={"transparent"}
                                            bgGradient={"linear(to-r, #D10781, #7D2FAD)"}
                                            width="150px"
                                            fontSize={{ base: "12", lg: "14" }}
                                        />
                                    </Flex>

                                </form>
                            )}
                        </Formik>


                    </ModalBody>
                    <ModalFooter>


                    </ModalFooter>
                </ModalContent>
            </Modal>


        </DashBoardLayout>
    );
};

export default Premium;
