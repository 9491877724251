import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import images from "../../constants/images";

const DashboardCard = ({
  onClick = () => { },
  icon,
  title = "",
  text = "",
  isHome = false,
  count = "0"
}) => {
  return (
    <Box
      w={"100%"}
      p="5"
      borderRadius={10}
      backgroundColor="white"
      boxShadow={"lg"}
      shadow="#AF97E9"
      cursor={"pointer"}
      onClick={onClick}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems="center" >
        <Box display={"flex"} alignItems="center" gap={5}>
          {icon}
          <Text fontWeight={"700"} fontSize="18">
            {title}
          </Text>
        </Box>
        {isHome && (
          <Text fontWeight={"700"} fontSize={"24"} > {count} </Text>
        )}
      </Box>
      <Text fontWeight={"500"} fontSize="14" mt={4} mb={5}>
        {text}
      </Text>
    </Box>
  );
};

export default DashboardCard;
