import Login from "../pages/Auth/Login";
import DashboardHome from "../pages/dashboard/DashboardHome";
import ImageGenerationDashBoard from "../pages/dashboard/ImageGenerationDashBoard";
import TextArticleDashBoard from "../pages/dashboard/TextArticleDashBoard";
import Register from "../pages/Auth/Register";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import VerifyCode from "../pages/Auth/VerifyCode";
import ChangePassword from "../pages/Auth/ChangePassword";
import Profile from "../pages/dashboard/Profile";
import TemplatesDashBoard from "../pages/dashboard/TemplatesDashBoard";
import ImageGeneration from "../pages/ImageGeneration";
import TextGeneration from "../pages/TextGeneration";
import Templates from "../pages/Templates";
import CustomizedGenerations from "../pages/dashboard/CustomizedGenerations";
import ChatBotDashBoard from "../pages/dashboard/ChatBotDashBoard";
import SavedDashBoard from "../pages/dashboard/SavedDashBoard";
import Premium from "../pages/dashboard/Premium";
import Payment from "../pages/dashboard/Payment";
import Chatbot from "../pages/Chatbot";

export const routes = {
  home: {
    path: "/",
    name: "Dashboard",
    component: DashboardHome,
    isSecured: true,
    isExact: true,
  },
  login: {
    path: "/login",
    name: "Login",
    component: Login,
    isSecured: false,
    isExact: true,
  },
  register: {
    path: "/register",
    name: "Register",
    component: Register,
    isSecured: false,
    isExact: true,
  },
  forgotPassword: {
    path: "/forgot_password",
    name: "Forgot Password",
    component: ForgotPassword,
    isSecured: false,
    isExact: true,
  },
  verifyCode: {
    path: "/reset_password/:resetToken",
    name: "Verify Code",
    component: VerifyCode,
    isSecured: false,
    isExact: true,
  },
  changePassword: {
    path: "/change_password",
    name: "Change Password",
    component: ChangePassword,
    isSecured: false,
    isExact: true,
  },
  dashboardHome: {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardHome,
    isSecured: true,
    isExact: true,
  },
  imageGenerationDashBoard: {
    path: "/dashboard/image-generation",
    name: "ImageGenerationDashBoard",
    component: ImageGenerationDashBoard,
    isSecured: true,
    isExact: true,
  },
  textArticleDashBoard: {
    path: "/dashboard/text-article",
    name: "TextArticle",
    component: TextArticleDashBoard,
    isSecured: true,
    isExact: true,
  },
  templatesDashBoard: {
    path: "/dashboard/templates",
    name: "Templates",
    component: TemplatesDashBoard,
    isSecured: true,
    isExact: true,
  },
  profile: {
    path: "/profile",
    name: "Profile",
    component: Profile,
    isSecured: true,
    isExact: true,
  },
  imageGeneration: {
    path: "/image-generation",
    name: "ImageGeneration",
    component: ImageGeneration,
    isSecured: false,
    isExact: true,
  },
  textGeneration: {
    path: "/text-generation",
    name: "TextGeneration",
    component: TextGeneration,
    isSecured: false,
    isExact: true,
  },
  templates: {
    path: "/templates",
    name: "Templates",
    component: Templates,
    isSecured: false,
    isExact: true,
  },
  customizedGenerations: {
    path: "/customized-generations",
    name: "CustomizedGenerations",
    component: DashboardHome,
    isSecured: true,
    isExact: true,
  },
  chatBotDashboard: {
    path: "/dashboard/chatbot",
    name: "ChatBot",
    component: ChatBotDashBoard,
    isSecured: true,
    isExact: true,
  },
  premium: {
    path: "/dashboard/premium",
    name: "Premium",
    component: Premium,
    isSecured: true,
    isExact: true,
  },
  payment: {
    path: "/dashboard/payment",
    name: "Payment",
    component: Payment,
    isSecured: true,
    isExact: true,
  },
  chatbot: {
    path: "/chatbot",
    name: "ChatBot",
    component: Chatbot,
    isSecured: false,
    isExact: true,
  },

  saved: {
    path: "/saved/:type",
    name: "Saved",
    component: SavedDashBoard,
    isSecured: true,
    isExact: true,
  },
};

export const navigation = Object.values(routes);
