const steps = [
    //lang-style
    {
      id: "intro",
      attachTo: { element: "#image", on: "bottom" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
      buttons: [
        {
          classes: "btn btn-custom",
          text: "Next",
          action() {
            return this.next();
          },
        },
      ],
      title: "Image List Tab !",
      text: ["You can see generated images in here."],
    },
    {
      id: "intro1",
      attachTo: { element: "#text", on: "bottom" },
      buttons: [
        {
          text: "Back",
          classes: "btn btn-light",
          action() {
            return this.back();
          },
        },
        {
          text: "Quit !",
          classes: "btn btn-primary",
          action() {
            return this.complete();
          },
        },
      ],
      title: "Text List Tab !",
      text: ["You can see generated texts in here."],
    },
  ];
  
  export default steps;