import React, { useState, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { navigation, routes } from "./constants/routes";
import { Fallback, withSplashScreen } from "./components";
import { UserStore, UserContext } from './context/user';
import '@fontsource/inter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SideBar, TopBar, Footer, UpdatePlanCard } from "./components";
import { useMediaQuery } from "react-responsive";
import { Box } from "@chakra-ui/react";

function App() {
  const [state, dispatch] = useContext(UserContext);
  const [hover, setHover] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <ToastContainer />
      {/* { state.isLoggedIn &&
        <Box ml={6}>
          {!isMobile && <SideBar onHoverChange={setHover} pt="2vh" />}
        </Box>} */}


      <React.Suspense fallback={<Fallback />}>
        <Routes>
          {navigation.map((route, index) => {
            let redirect = false;
            if (route.redirect) {
              redirect = route.redirectPath;
            }

            if (!state.isLoggedIn) {
              redirect = routes.login.path;
            }

            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
                exact={route.isExact}
              />
            );
          })}
        </Routes>
      </React.Suspense>
    </>

  );
}

export default withSplashScreen(App);
