import { instance as axios } from './axiosInstance';

const login = (data) => axios.post('/users/login', data);

const register = (data) => axios.post('/users', data);

const forgotpassword = (data) => axios.post('/users/forgotpassword', data);

const resetpassword = (resetToken, data) => axios.put(`/users/resetpassword/${resetToken}`, data);

const getProfile = () => axios.get(`/users/me`)

const updateProfile = (data) => axios.put(`/users/updateprofile`,data) 

const dashboradInfo = () => axios.get("/users/dashboard")

const userUsage = () => axios.get("/users/usage")

const userUsageMonth = (data) => axios.post(`/usage/month`,data)

const stepsUpdate = (data) => axios.post("/users/complete-steps",data)

export {
    login,
    register,
    forgotpassword,
    resetpassword,
    getProfile,
    updateProfile,
    dashboradInfo,
    userUsage,
    userUsageMonth,
    stepsUpdate
  };