import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import images from "../../constants/images";
import { DefaultButton, NavbarMenu } from "..";
import { Squirrel, Menu as Nav } from "../icons";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const AuthHeader = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box as="nav" backgroundColor={"white"}>
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        minH={"100px"}
        marginX={"auto"}
        alignItems="center"
        justifyContent={"space-around"}
        pt="3"
        pb="3"
      >
        <Image
          src={images.logoBlack}
          w={{ base: "50%", sm: "30%", md: "20%", lg: "15%" }}
        />
        {!isMobile && (
          <Box
            display={"grid"}
            flexDirection={"row"}
            justifyContent={"space-around"}
            gap="5"
            mt={{ base: "3", lg: "0" }}
            gridTemplateColumns={{
              base: "1fr 1fr 1fr",
              sm: "1fr 1fr 1fr 1fr",
            }}
            w="30%"
          >
            <Box display={"flex"} gap="2" cursor={"pointer"}>
              <Text fontWeight={"500"} fontSize="16" color={"orbBlack"}>
                Home
              </Text>
            </Box>
            <NavItem text="Products" menu={<NavbarMenu />} />
            <Box display={"flex"} gap="2" cursor={"pointer"}>
              <Text fontWeight={"500"} fontSize="16" color={"orbBlack"}>
                Pricing
              </Text>
            </Box>
            {/* <NavItem text="Pricing" /> */}
            {/* <NavItem text="Business" /> */}
            <NavItem text="Resources" />
          </Box>
        )}
        <Box display={"flex"} gap={"5"} mt={{ base: "3", lg: "0" }}>
          <DefaultButton
            as="button"
            color="white"
            backgroundColor="pink"
            text={"Login"}
            borderRadius={10}
            width="120px"
            boxShadow={"2xl"}
            onClick={() => navigate(routes.login.path)}
          />
          <DefaultButton
            as="button"
            rightIcon={<Icon as={Squirrel} />}
            color="pink"
            borderColor="pink"
            borderWidth={1}
            backgroundColor="white"
            text={"Try For Free"}
            borderRadius={10}
            width="120px"
            onClick={() => navigate(routes.register.path)}
          />
          {isMobile && (
            <Icon as={Nav} boxSize={10} onClick={() => setShowNav(!showNav)} />
          )}
        </Box>
        {showNav && isMobile && (
          <Box
            display={"grid"}
            flexDirection={"row"}
            justifyContent={"space-around"}
            gap="5"
            mt={{ base: "3", lg: "0" }}
            gridTemplateColumns={{
              base: "1fr 1fr",
              sm: "1fr 1fr 1fr 1fr",
            }}
            w="90%"
          >
            <Box display={"flex"} gap="2" cursor={"pointer"}>
              <Text fontWeight={"500"} fontSize="16" color={"orbBlack"}>
                Home
              </Text>
            </Box>
            <NavItem text="Products" menu={<NavbarMenu />} />
            <Box display={"flex"} gap="2" cursor={"pointer"}>
              <Text fontWeight={"500"} fontSize="16" color={"orbBlack"}>
                Pricing
              </Text>
            </Box>
            {/* <NavItem text="Pricing" /> */}
            {/* <NavItem text="Business" /> */}
            <NavItem text="Resources" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AuthHeader;

const NavItem = ({ text, menu }) => {
  return (
    <Menu isLazy>
      <MenuButton>
        <Box display={"flex"} gap="2" cursor={"pointer"}>
          <Text fontWeight={"500"} fontSize="16" color={"orbBlack"}>
            {text}
          </Text>
          <Text color={"#1C1B1F"}>{">"}</Text>
        </Box>
      </MenuButton>
      <MenuList
        borderTopWidth={"0.5"}
        borderBottom="none"
        borderRight="none"
        borderLeft="none"
        shadow={"lg"}
        borderColor="#4285F4"
        minW={"45vw"}
        borderRadius={10}
      >
        {menu}
      </MenuList>
    </Menu>
  );
};
