import React from "react";

const CustomizedGenerations = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 18.3037C1.45 18.3037 0.979167 18.1079 0.5875 17.7162C0.195833 17.3245 0 16.8537 0 16.3037V2.30371C0 1.75371 0.195833 1.28288 0.5875 0.891211C0.979167 0.499544 1.45 0.303711 2 0.303711H16C16.55 0.303711 17.0208 0.499544 17.4125 0.891211C17.8042 1.28288 18 1.75371 18 2.30371V7.95371C17.6833 7.80371 17.3583 7.68288 17.025 7.59121C16.6917 7.49954 16.35 7.42871 16 7.37871V2.30371H2V11.3037H6.2C6.35 11.7537 6.6 12.1454 6.95 12.4787C7.3 12.812 7.69167 13.0454 8.125 13.1787C8.075 13.512 8.04167 13.8495 8.025 14.1912C8.00833 14.5329 8.025 14.8704 8.075 15.2037C7.475 15.087 6.9125 14.8662 6.3875 14.5412C5.8625 14.2162 5.4 13.8037 5 13.3037H2V16.3037H8.325C8.44167 16.6704 8.575 17.0204 8.725 17.3537C8.875 17.687 9.05833 18.0037 9.275 18.3037H2ZM14.025 19.3037L13.725 17.8037C13.525 17.7204 13.3375 17.6329 13.1625 17.5412C12.9875 17.4495 12.8083 17.337 12.625 17.2037L11.175 17.6537L10.175 15.9537L11.325 14.9537C11.2917 14.7537 11.275 14.537 11.275 14.3037C11.275 14.0704 11.2917 13.8537 11.325 13.6537L10.175 12.6537L11.175 10.9537L12.625 11.4037C12.8083 11.2704 12.9875 11.1579 13.1625 11.0662C13.3375 10.9745 13.525 10.887 13.725 10.8037L14.025 9.30371H16.025L16.325 10.8037C16.525 10.887 16.7125 10.9745 16.8875 11.0662C17.0625 11.1579 17.2417 11.2704 17.425 11.4037L18.875 10.9537L19.875 12.6537L18.725 13.6537C18.7583 13.8537 18.775 14.0704 18.775 14.3037C18.775 14.537 18.7583 14.7537 18.725 14.9537L19.875 15.9537L18.875 17.6537L17.425 17.2037C17.2417 17.337 17.0625 17.4495 16.8875 17.5412C16.7125 17.6329 16.525 17.7204 16.325 17.8037L16.025 19.3037H14.025ZM15.025 16.3037C15.575 16.3037 16.0458 16.1079 16.4375 15.7162C16.8292 15.3245 17.025 14.8537 17.025 14.3037C17.025 13.7537 16.8292 13.2829 16.4375 12.8912C16.0458 12.4995 15.575 12.3037 15.025 12.3037C14.475 12.3037 14.0042 12.4995 13.6125 12.8912C13.2208 13.2829 13.025 13.7537 13.025 14.3037C13.025 14.8537 13.2208 15.3245 13.6125 15.7162C14.0042 16.1079 14.475 16.3037 15.025 16.3037Z"
        fill="black"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default CustomizedGenerations;
