import React, { useRef } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaCopy } from 'react-icons/fa';

const darkTheme = {
  ...solarizedlight,
  'code[class*="language-"]': {
    ...solarizedlight['code[class*="language-"]'],
    background: 'black',
    borderRadius: '0 0 8px 8px',
  },
  'pre[class*="language-"]': {
    ...solarizedlight['pre[class*="language-"]'],
    background: 'black',
    borderRadius: '0 0 8px 8px',
  },
  'comment': {
    color: '#7f9f7f',
  },
  'keyword': {
    color: '#f92672',
  },
  'function': {
    color: '#66d9ef',
  },
  'string': {
    color: '#e6db74',
  },
  'punctuation': {
    color: 'white',
  },
  'variable': {
    color: 'white',
  },
  'operator': {
    color: 'white',
  },
};

const CodeBlock = ({ language, value }) => {
  const textareaRef = useRef(null);

  const handleCopy = () => {
    textareaRef.current.select();
    document.execCommand('copy');
  };

  return (
    <div style={{ position: 'relative', background: 'grey', borderRadius: '8px',minWidth:"100%" }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
        <p style={{ margin: '0', paddingLeft: "8px",paddingTop: "6px",fontSize: '0.9rem', }}>{language} : </p>
        <button
          onClick={handleCopy}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            paddingRight: "10px",
            paddingTop: "6px",
            fontSize: '0.8rem',
          }}
        >
          <FaCopy style={{ marginRight: '0.3rem' }} />
          <span>Copy Code</span>
        </button>
      </div>
      <SyntaxHighlighter language={language} style={darkTheme}>
        {value}
      </SyntaxHighlighter>
      <textarea
        ref={textareaRef}
        value={value}
        readOnly
        style={{ position: 'absolute', left: '-9999px' }}
      />
    </div>
  );
};

export default CodeBlock;
