import React, { useState, useContext } from "react";
import { Box, Text, Button,useDisclosure,Divider } from "@chakra-ui/react";
import { AuthLayout, AuthInput,PasswordInput,LoadingModal } from "../../components";
import { routes } from "../../constants/routes";
import { useNavigate,useParams } from "react-router-dom";
import { resetpassword } from "../../api/user";

const VerifyCode = () => {
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const [passwordx, setPasswordx] = useState("")
  
  const [loginCheck, setLoginCheck] = useState(false)

  const loadingVideoModal = useDisclosure();
  const onSubmit = async () => {
    loadingVideoModal.onOpen();
    try {
      const response = await resetpassword(resetToken,{password : passwordx});

      if(response?.status == 200 || response?.status == 201){
        setLoginCheck(true)
        
      } 

    } catch (error) {
      console.log(error);
    } 
    finally {
      loadingVideoModal.onClose();
    }
  };


  return (
    <AuthLayout>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="center"
        h={"70%"}
      >

        {loginCheck ?   <Box display={"flex"} flexDirection="column" w={"70%"}>
          <Text fontWeight={"500"} fontSize="28" color={"white"} mb="5">
            New Password
          </Text>
          <Divider mb={4} color={"lightGray"} />
          <Text fontSize={"16"} fontWeight={"500"} color="gray" mb="7">
            Your password has been changed successfully.
          </Text>

          <Button
            backgroundColor={"pink"}
            borderRadius={"10"}
            height={"48px"}
            isFullWidth
            my={"5"}
            onClick={() => navigate(routes.login.path)}
            
          >
            <Text color={"white"} fontWeight={"500"} fontSize={"16"}>
              Login
            </Text>
          </Button>
        </Box>
        :
        
        <Box display={"flex"} flexDirection="column" w={"70%"}>
        <Text fontWeight={"500"} fontSize="28" color={"white"} mb="5">
          New Password
        </Text>
        <Divider mb={4} color={"lightGray"} />
        <Text fontSize={"16"} fontWeight={"500"} color="gray" mb="7">
          Enter Your New Password
        </Text>

        <PasswordInput label="Password" onChange={(e) => setPasswordx(e.target.value)} placeholder="Create new password" _placeholder={{ opacity: 0.6 }} />
        {/* <Text fontWeight={"500"} fontSize="14" color={"gray"} mb="5" alignSelf={"center"}>
          Didn’t work? Send me another code.
        </Text> */}
        <Button
          backgroundColor={"pink"}
          borderRadius={"10"}
          height={"48px"}
          isFullWidth
          my={"5"}
          //onClick={() => navigate(routes.login.path)}
          onClick={ () => onSubmit() }
        >
          <Text color={"white"} fontWeight={"500"} fontSize={"16"}>
            Change Password
          </Text>
        </Button>
      </Box>}
      
      </Box>
      <LoadingModal
        onClose={loadingVideoModal.onClose}
        isOpen={loadingVideoModal.isOpen}
      />
    </AuthLayout>
  );
};

export default VerifyCode;
