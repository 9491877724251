import React from "react";
import { Box } from "@chakra-ui/react";
import { AuthHeader, CardItem } from "..";
import images from "../../constants/images";

const MainLayout = ({ children }) => {
  return (
    <Box
      h={{ base: "200vh", md: "120vh" , lg: "100vh"}}
      backgroundImage={images.background2}
      backgroundPosition={"center"}
      backgroundSize={"cover"}
      backgroundRepeat={"no-repeat"}
    >
      <AuthHeader />
      <Box
        display={"flex"}
        minH={"80vh"}
        flexDirection={{ base: "column", sm: "column", md: "row", lg: "row" }}
        alignItems={{ base: "center", md: "flex-start" }}
        justifyContent="space-between"
        gap={"10"}
        pt={{ base: 10, md: 16 }}
        mx={{ base: 10, md: 14, lg: 24 }}
      >
        {children}
      </Box>
      <Box
        display="grid"
        h={130}
        gridGap={{ base: "5", lg: "2" }}
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr 1fr",
          md: "1fr 1fr",
          lg: "1fr 1fr 1fr",
        }}
        alignItems={"center"}
        justifyItems="center"
        mx={{ base: 0, lg: 10 }}
        mt={{base:"8", sm: "0"}}
      >
        <CardItem title="1 Million +" label="Users" />
        <CardItem title="30 K+" label="Download" />
        <CardItem title="50 +" label="Different Category" />
      </Box>
    </Box>
  );
};

export default MainLayout;
