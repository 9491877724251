import React from "react";
import { Box, Text } from "@chakra-ui/react";

const RadioButton = ({
  onClick = () => {},
  isCheck = false,
  text,
  ...props
}) => {
  return (
    <Box display={"flex"} alignItems="center" gap={"8"} my="4">
      <Box
        borderWidth={1}
        borderColor="gray"
        boxSize={"5"}
        borderRadius="100"
        onClick={onClick}
        backgroundColor={isCheck && "gray"}
        cursor="pointer"
        p={"1"}
        {...props}
      />
      <Text fontWeight={"400"} fontSize="16" color={"gray"}>
        {text}
      </Text>
    </Box>
  );
};

export default RadioButton;
