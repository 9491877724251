import { instance as axios } from './axiosInstance';

const getChat = () => axios.get(`/chat/messages`)

const postChat = (data) => axios.post(`/chat/completions`,data)

const deleteChat = () => axios.get(`/chat/delete`)

export {
    getChat,
    postChat,
    deleteChat};