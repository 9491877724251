import React from "react";

const Medium = (props) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 29 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.2994 8.25C16.2994 12.527 12.7708 16 8.39969 16C4.02853 16 0.5 12.527 0.5 8.25C0.5 3.97296 4.02853 0.5 8.39969 0.5C12.7708 0.5 16.2994 3.97296 16.2994 8.25ZM24.9539 8.25C24.9539 12.2704 23.1809 15.5381 21.0041 15.5381C18.8273 15.5381 17.0542 12.2704 17.0542 8.25C17.0542 4.22958 18.8273 0.961918 21.0041 0.961918C23.1809 0.961918 24.9539 4.21247 24.9539 8.25ZM28.5 8.25C28.5 11.8598 27.8856 14.7853 27.1132 14.7853C26.3408 14.7853 25.7263 11.8598 25.7263 8.25C25.7263 4.64018 26.3408 1.71468 27.1132 1.71468C27.8856 1.71468 28.5 4.64018 28.5 8.25Z"
        fill="#0C0C0C"
      />
    </svg>
  );
};

export default Medium;
