import React, { useEffect } from "react";
import {
    Text,
    Box,
    Icon,
    Image,
    Divider,
    Textarea,
    useDisclosure,
    Badge,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react";
import {
    DefaultButton,
} from "../";
import { AiOutlineClockCircle } from "react-icons/ai";
import images from "../../constants/images";

const DailyLimit = ({ onClose = () => { }, isOpen = false }) => {

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                boxShadow="none"         
                backgroundPosition="center"
                color="whiteAlpha.200"
                backgroundSize="cover"
            >

                <ModalBody>
                    <Text
                        textAlign="center"
                        fontSize="2xl"
                        fontWeight="bold"
                        mb={4}
                        mt={6}
                        pt={6}
                        sx={{
                            color: "black",
                            fontFamily: "Montserrat, sans-serif",
                            fontStyle: "italic",
                            textTransform: "uppercase",
                        }}
                    >
                        Your daily limit is reached! :)
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <DefaultButton
                        text={"Close"}
                        //rightIcon={<Icon as={Pencil} />}
                        as="button"
                        color="white"
                        fontWeight="bold"
                        borderRadius={10}
                        mb="1"
                        borderWidth={1}
                        borderColor={"white"}
                        backgroundColor={"transparent"}
                        bgGradient={"linear(to-r, #D10781, #7D2FAD)"}
                        boxShadow={"xl"}
                        width="150px"
                        alignSelf="center"
                        onClick={onClose}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DailyLimit;
