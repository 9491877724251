import React, { useState } from "react";
import { Box, Text, Image, Icon, Center, useDisclosure } from "@chakra-ui/react";
import { BsCheckCircle } from "react-icons/bs";
import {
    DefaultButton,
} from "../../components";
import {
    PaymentModal
} from "../../components";

const SelectUpdateCard = ({ title, infoTitle, infoTitle2, listItems, butonLabel, isWhite }) => {

    const paymentModal = useDisclosure();



    return (

        <>
            <Box backgroundColor={isWhite ? "white" : "rgba(255, 255, 255, 0.80)"} borderRadius={30} minH="75vh" position="relative">
                <Box display={"flex"} alignItems="center" p="2">
                    <Center flex="1" pt={2}>
                        <Text fontWeight={"700"} color="black" fontSize="18">
                            {title}
                        </Text>
                    </Center>

                </Box>

                <Box display={"flex"} alignItems="center" p="2">
                    <Center flex="1" pt={2} alignItems="end">
                        <Text fontWeight={"400"} alignItems="bottom" color="#D10781" fontSize="38">
                            <strong> {infoTitle} </strong>
                        </Text>

                        <Text fontWeight={"800"} alignItems="bottom" color="#D10781" fontSize="26">
                            &nbsp;  {infoTitle2}
                        </Text>
                    </Center>
                </Box>

                <Box px="20" style={{ overflowY: 'auto', maxHeight: '55vh' }}>
                    {listItems && listItems.map((item, index) => (
                        <Box key={index} display="flex" alignItems="center" p="2">
                            <Icon as={BsCheckCircle} color="#7D2FAD" boxSize={6} />
                            <Text ml="2" fontSize="18" whiteSpace="normal" wordBreak="break-word">
                                {item}
                            </Text>
                        </Box>
                    ))}
                </Box>

                <Box display={"flex"} p="2" gap="4"></Box>

                <Center position="absolute" bottom="5" left="50%" transform="translateX(-50%)">
                    <DefaultButton
                        text={butonLabel}
                        as="button"
                        p={4}
                        color="white"
                        fontWeight="bold"
                        borderRadius={10}
                        borderWidth={1}
                        bgGradient="linear(to-r, #D10781, #7D2FAD)"
                        backgroundColor="transparent"
                        width="250px"
                        fontSize="14"
                        boxShadow="xl"
                        onClick={() => paymentModal.onOpen()}
                    />
                </Center>


            </Box>

            <PaymentModal
                onClose={paymentModal.onClose}
                isOpen={paymentModal.isOpen}
            />
        </>
    );
};

export default SelectUpdateCard;
