import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: 'Inter',
  },
  colors: {
    backgroundColor: "#fff",
    primary: "#297AF3",
    default: "#515151",
    light: "#f9f9f9",
    gray: "#D9D9D9",
    gray3: "#7D8398",
    lightGray: "#B9B9B9",
    dark: "#292D32",
    pink: "#D10781",
    progressColor: "#026BEE",
    progressBackground: "#F1F1F1",
    gray6 : "#696969",
    orbBlack: "#121212",
    emptySpinner: "#AC9AB3",
    orbPurple2: "#613DBB",
    tagBlue: "#395996",
    blur: "rgba(255, 255, 255, 0.1)",
    main: {
      50: "#e0f1ff",
      100: "#b3d2ff",
      200: "#83b4fb",
      300: "#5496f7",
      400: "#2678f3",
      500: "#0c5ed9",
      600: "#0349aa",
      700: "#00347b",
      800: "#001f4c",
      900: "#000a1f",
    },
  },
});

export default theme;
