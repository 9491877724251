import React from "react";
import { Box, Divider, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      mt={5}
    >
      <Divider color="white" opacity={0.2} />
      <Text fontSize={12} fontWeight="400" color={"white"} mt="2">
        Copyright ©2023 Orbina. All Rights Reserved
      </Text>
    </Box>
  );
};

export default Footer;
