import React from "react";
import { InputGroup, Input, Text } from "@chakra-ui/react";

const PasswordInput = ({ label, inputGroupProps = {}, ...props }) => {
  return (
    <>
      {label && (
        <Text fontSize={16} fontWeight={"400"} mb="-2" color="#D9D9D9">
          {label}
        </Text>
      )}
      <InputGroup
        my={"3"}
        backgroundColor={"white"}
        borderRadius="10"
        size={"lg"}
        {...inputGroupProps}
      >
        <Input
          type={"password"}
          placeholder="Parola"
          borderWidth={0}
          {...props}
        />
      </InputGroup>
    </>
  );
};

export default PasswordInput;
