import React from "react";

const Pencil = (props) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.66667 13.0002L8.33334 10.3335H13.6667V13.0002H5.66667ZM1.66667 11.6668H2.6L8.35 5.91683L7.41667 4.9835L1.66667 10.7335V11.6668ZM11.2 4.95016L8.36667 2.15016L9.3 1.21683C9.55556 0.961274 9.86945 0.833496 10.2417 0.833496C10.6139 0.833496 10.9278 0.961274 11.1833 1.21683L12.1167 2.15016C12.3722 2.40572 12.5056 2.71405 12.5167 3.07516C12.5278 3.43627 12.4056 3.74461 12.15 4.00016L11.2 4.95016ZM10.2333 5.9335L3.16667 13.0002H0.333336V10.1668L7.4 3.10016L10.2333 5.9335Z"
        fill="white"
      />
    </svg>
  );
};

export default Pencil;
