/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Text,
  Icon,
  IconButton,
  Menu,
  MenuButton as CMenuButton,
  MenuList,
  useDisclosure,
  HStack,
  Divider,
  Center
} from "@chakra-ui/react";
import {
  DashBoardLayout,
  DefaultButton,
  InputWithTitle,
  DropDownInput,
  LoadingModal,
  FeedBackModal,
  SquirrelCard,
  TextEditor,
} from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { Logout, Pricing, Profile } from "../icons";
import { routes } from "../../constants/routes";
import { UserContext } from "../../context/user";
import React, { useContext, useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { dashboradInfo } from "../../api/user";
import { BsCreditCard2Back } from "react-icons/bs";

const MenuButton = ({ icon, activeIcon, iconSize = 5 }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, dispatch] = useContext(UserContext);
  const feedBackModal = useDisclosure();
  const logout = () => {
    dispatch({ type: "LOGOUT" });
    window.location.reload(false);
    navigate(routes.login.path);
  };

  return (
    <Menu isLazy>




      <CMenuButton display="flex" alignItems="center">
        <IconButton
          colorScheme="white"
          icon={
            <Icon
              boxSize={iconSize}
              as={location.pathname === "/profile" ? activeIcon : icon}
            />
          }
        />
      </CMenuButton>



      <MenuList border="none" shadow={"rgba(0, 0, 0, 0.9) 0px 2px 20px"} borderRadius={8} p="1">
        <Text fontWeight="bold" fontSize="17" ml={2} mt={2} mr={2} mb={1} color="black" textAlign={"center"}>
          {state.name}
        </Text>
        <Divider mt={"2"} mb={"3"} color="lightGray"></Divider>
        <Box
          mt={"4"}
          ml={"2"}
        >
          <MenuText
            icon={Profile}
            text="Profile"
            onClick={() => navigate(routes.profile.path)}
          />
        </Box>

        {/* <Box
          mt={"4"}
          ml={"2"}
        >
          <MenuText
            icon={BsCreditCard2Back}
            text="Payment Methods"
            onClick={() => navigate(routes.payment.path)}
          />
        </Box> */}

        {/* <MenuText icon={Pricing} text="Plans/Pricing" /> */}

        <Box
          mt={"4"}
          ml={"2"}
          mb={"2"}
        >
          <MenuText onClick={() => logout()} icon={Logout} text="Sign Out" />
        </Box>

      </MenuList>

      <FeedBackModal
        onClose={feedBackModal.onClose}
        isOpen={feedBackModal.isOpen}
        modalType="general"
      />

    </Menu>
  );
};

export default MenuButton;

const MenuText = ({ onClick = () => { }, icon, text }) => {

  return (
    <>
      <Box
        onClick={onClick}
        cursor="pointer"
        display={"flex"}
        alignItems="center"
        gap="2"
        ml={"1"}
      >
        <Icon as={icon} boxSize="5" />
        <Text fontWeight={"500"} fontSize="16">
          {text}
        </Text>
      </Box>
    </>
  );
};
