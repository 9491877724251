/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Text,
  Image,
  Input,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useBoolean,
  useDisclosure
} from "@chakra-ui/react";
import { DashBoardLayout, SavedCard, DefaultButton, LoadingModal } from "../../components";
import images from "../../constants/images";
import { NextArrow, DownIcon, Download } from "../../components/icons";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/DataTable";
import { TableData } from "../../constants/data";
import { getTexts, listsimage } from "../../api/ai";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { routes } from "../../constants/routes";
import { stepsUpdate } from "../../api/user";
import { UserContext } from "../../context/user";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import newSteps from './steps/saved';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: "smooth", block: "center" },
  },
  useModalOverlay: true
};

const Autton = () => {


  const tour = useContext(ShepherdTourContext);
  const [state, dispatch] = useContext(UserContext);

  const handleStepCompletion = async (step) => {
    dispatch({ type: 'UPDATE_STEP', payload: { step, value: true } });

    try {
      const response = await stepsUpdate({ stepComplete3: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!state.stepComplete3 && !tour.isActive()) {
      tour.start();
    }

    tour.on('complete', () => {
      handleStepCompletion('stepComplete3');
    });

  }, [tour]);

  return (<> </>);
}



const tabs = {
  imageList: "imageList",
  textList: "textList",
};

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("topic", {
    cell: (info) => info.getValue(),
    header: "Title",
  }),
  columnHelper.accessor("keyword", {
    cell: (info) => info.getValue(),
    header: "Primary keywords",
  }),

  columnHelper.accessor("showText2", {
    cell: (info) => info.getValue(),
    header: "Text",
  }),
  columnHelper.accessor("createdAt", {
    cell: (info) => info.getValue(),
    header: "Date Created",
  }),
  columnHelper.accessor("wordCount", {
    cell: (info) => info.getValue(),
    header: "Word Count",
  }),
  columnHelper.accessor("language", {
    cell: (info) => info.getValue(),
    header: "Language",
  }),
  columnHelper.accessor("downloadBtn", {
    cell: (info) => info.getValue(),
    header: "Download",
  }),
];

function downloadTxtFile(notReplacedText) {
  if (notReplacedText) {
    const myHTMLString = notReplacedText;
    const parser = new DOMParser();
    const doc = parser.parseFromString(myHTMLString, "text/html");
    const myTextString = doc.body.textContent;
    const element = document.createElement("a");
    const file = new Blob([myTextString], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "Orbina_Text.txt";
    document.body.appendChild(element);
    element.click();
  }
}

const SavedDashBoard = () => {
  const { type } = useParams();
  const [tab, setTab] = useState();
  const [textTable, setTextTable] = useState([]);
  const [savedImg, setSavedImg] = useState([]);
  const [hover, setHover] = useBoolean();

  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpen = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setSelectedRow(null);
  };

  const [selectedRow2, setSelectedRow2] = useState(null);

  const handleOpen2 = (rowIndex) => {
    setSelectedRow2(rowIndex);
  };

  const handleClose2 = () => {
    setSelectedRow2(null);
  };

  function downloadImage(imgUrl) {
    if (imgUrl) {
      fetch(imgUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "Orbina_Saved_Image.jpg";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  }

  const [searchText, setSearchText] = useState("");

  const filteredData = savedImg.filter((el) => {
    if (savedImg.length > 0) {
      if (searchText === "") {
        return el;
      } else {
        return el.prompt.toLowerCase().includes(searchText);
      }
    }
  });
  const loadingVideoModal = useDisclosure();
  useEffect(() => {

    document.title = 'Saved | Orbina AI ';

    const getData = async () => {
      loadingVideoModal.onOpen();
      const response2 = await listsimage();
      setSavedImg(response2?.data);

      const response = await getTexts();
      const getmedal = [];
      response?.data?.map((item, index) => {
        item["downloadBtn"] = (
          <DefaultButton
            borderColor={"white"}
            borderRadius={10}
            color="white"
            mb="1"
            backgroundColor={"transparent"}
            rightIcon={<Icon as={Download} />}
            width="50px"
            fontSize="20"
            onClick={() => downloadTxtFile(item?.translation)}
          />
        );

        //item["text"] = item?.text.replace(/\n/g, "<br/>");
        item["showText"] = (
          <DefaultButton
            text={"Show"}
            borderColor={"white"}
            borderRadius={10}
            color="white"
            borderWidth={1}
            mb="1"
            backgroundColor={"transparent"}
            boxShadow={"xl"}
            onClick={() => handleOpen(index)}
            width="130px"
            fontSize="14"
          />
        );

        item["showText2"] = (
          <DefaultButton
            text={"Show"}
            borderColor={"white"}
            borderRadius={10}
            color="white"
            borderWidth={1}
            mb="1"
            backgroundColor={"transparent"}
            boxShadow={"xl"}
            onClick={() => handleOpen2(index)}
            width="130px"
            fontSize="14"
          />
        );
        item["createdAt"] = new Date(item?.createdAt).toLocaleDateString(
          "en-EN",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );
        item["language"] = item?.language.toUpperCase();
        item["updatedAt"] = new Date(item?.updatedAt).toLocaleDateString(
          "en-EN",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );

        getmedal.push(item);
      });

      setTextTable(getmedal);
      loadingVideoModal.onClose();

    };

    getData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData
    .reverse()
    .slice(startIndex, endIndex);

  return (
    <DashBoardLayout title="Saved" modalType="other">
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <Autton />
        <Tabs isLazy defaultIndex={type == "text" ? 1 : 0}>
          <TabList
            borderColor={"#F2F2F2"}
            borderBottomWidth={1}
            gap={{ base: "20", md: "40", lg: "52" }}
          >
            <Tab
              _selected={{
                borderColor: "#A1E3CB",
              }}

            >
              <Text fontWeight={"700"} color="white" fontSize={"16"} id="image">
                Image List
              </Text>
            </Tab>
            <Tab
              _selected={{
                borderColor: "#A1E3CB",
              }}
            >
              <Text
                fontWeight={"700"}
                color="white"
                fontSize={"16"}
                cursor="pointer"
                onClick={() => setTab(tabs.textList)}
                id="text"

              >
                Text List
              </Text>
            </Tab>
          </TabList>
          <TabPanels>
            {/* initially mounted */}
            <TabPanel>
              <SearchArea
                tab={tab}
                setSearchText={setSearchText}
                butonTxt="New Image Generation"
              />
              <Box
                display="grid"
                gridGap="4"
                mt={2}
                gridTemplateColumns={{
                  base: "1fr",
                  md: "1fr 1fr",
                  lg: "1fr 1fr 1fr 1fr",
                  xl: "1fr 1fr 1fr 1fr 1fr",
                }}
                mb={5}
              >
                {currentItems.map((item) => {
                  return (
                    <>
                      <Box
                        w={{ base: "100%" }}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          w={{ base: "100%" }}
                        >
                          <Box margin="0 3px"> {/* İstediğiniz boşluğu burada ayarlayabilirsiniz */}
                            <SavedCard
                              image={item?.url || item?.url1}
                              image2={item?.style !== 'Realistic V2' ? null : item?.url2} // Eğer style 'realistic v2' dışında ise, image2 gösterme
                              image3={item?.style !== 'Realistic V2' ? null : item?.url3} // Eğer style 'realistic v2' dışında ise, image3 gösterme
                              image4={item?.style !== 'Realistic V2' ? null : item?.url4} // Eğer style 'realistic v2' dışında ise, image4 gösterme
                              text={item?.style + " / " + item?.prompt}
                              isSaved={true}
                              onClick={() => downloadImage(item?.url)}
                            />
                          </Box>
                         
                        </Box>

                        

                        {/* <Text
                          borderColor={"white"}
                          color="white"
                          mt="2"
                          backgroundColor={"transparent"}
                          fontSize="15"
                          px={4}
                        >
                          {item?.prompt}

                        </Text> */}


                      </Box >
                    </>
                  );
                })}
              </Box>
              <Pagination
                mt={3}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </TabPanel>
            {/* initially not mounted */}
            <TabPanel>
              <SearchArea
                tab={tab}
                setSearchText={setSearchText}
                butonTxt="New Text Generation"
              />
              <DataTable columns={columns} data={textTable} />
            </TabPanel>
          </TabPanels>
        </Tabs>

        {selectedRow !== null && (

          <Modal isOpen={true} onClose={handleClose2} size="md">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Generated Text</ModalHeader>
              <ModalBody maxHeight="60vh" overflowY="auto">
                {textTable[selectedRow2].translation}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleClose2}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        {selectedRow2 !== null && (

          <Modal isOpen={true} onClose={handleClose2} size="md" >
            <ModalOverlay minW="41vw" />
            <ModalContent minW="41vw">
              <ModalHeader>Generated Text</ModalHeader>
              <ModalBody>
                <Box
                  maxHeight="60vh"
                  overflowY="auto"
                >
                  <pre style={{ fontFamily: "Inter", whiteSpace: "pre-wrap", textAlign: "justify", padding: " 0 10px 0 0" }}>
                    {textTable[selectedRow2].translation}
                  </pre>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleClose2}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        <LoadingModal
          onClose={loadingVideoModal.onClose}
          isOpen={loadingVideoModal.isOpen}
        />
      </ShepherdTour>
    </DashBoardLayout >
  );
};

export default SavedDashBoard;

const SearchArea = ({ tab, setSearchText, butonTxt = "" }) => {
  const navigate = useNavigate();

  return (
    <Box
      display={"flex"}
      flexDirection={{ base: "column", md: "row" }}
      my="4"
      gap={"5"}
    >
      <Input
        placeholder="Name ArtWork"
        backgroundColor={"white"}
        size="md"
        type="search"
        onChange={(e) => {
          setSearchText(e?.target?.value?.toLowerCase());
        }}
      />
      <Box display={"flex"} gap="3">
        <DefaultButton
          text={`+ ${butonTxt}`}
          borderColor={"white"}
          borderRadius={10}
          color="white"
          borderWidth={1}
          mb="1"
          backgroundColor={"transparent"}
          boxShadow={"xl"}
          width="200px"
          fontSize="14"
          onClick={() => {
            if (tab == "textList") {
              navigate(routes.textArticleDashBoard.path);
            }
            if (tab == "imageList") {
              navigate(routes.imageGenerationDashBoard.path);
            }
          }}
        />
      </Box>
    </Box>
  );
};

function Pagination({ currentPage, totalPages, onPageChange }) {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => onPageChange(i)}
          className={currentPage === i ? "active" : ""}
          sx={{
            color: "white",
            border: "1px solid white",
            borderRadius: "12px",
            marginRight: "8px"
          }}
        >
          {i}
        </Button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="pagination">
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        sx={{
          color: "white",
          border: "1px solid white",
          borderRadius: "12px",
          marginRight: "8px"
        }}
      >
        Previous
      </Button>
      {renderPageNumbers()}
      <Button
        sx={{
          color: "white",
          border: "1px solid white",
          borderRadius: "12px",
          marginRight: "8px"
        }}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </Button>
    </div>
  );
}
