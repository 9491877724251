import React from "react";

const SvgDescription = (props) => {
  return (
    <svg
      width="25"
      height="30"
      fontSize={30}
      viewBox="0 0 25 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 24H18.5V21H6.5V24ZM6.5 18H18.5V15H6.5V18ZM3.5 30C2.675 30 1.96875 29.7063 1.38125 29.1188C0.79375 28.5312 0.5 27.825 0.5 27V3C0.5 2.175 0.79375 1.46875 1.38125 0.88125C1.96875 0.29375 2.675 0 3.5 0H15.5L24.5 9V27C24.5 27.825 24.2063 28.5312 23.6188 29.1188C23.0312 29.7063 22.325 30 21.5 30H3.5ZM14 10.5V3H3.5V27H21.5V10.5H14Z"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default SvgDescription;
