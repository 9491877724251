import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import images from "../../constants/images";
import CodeBlock from "./CodeBlock";

const parseLines = (lines) => {
  let isCodeBlock = false;
  let codeLanguage = '';
  let codeLines = [];
  const parsedLines = [];

  lines.forEach((line) => {
    if (isCodeBlock) {
      if (line === '```') {
        // Kod bloğunun sonunu bulduk, bir CodeBlock bileşeni oluştur
        isCodeBlock = false;
        parsedLines.push(
          <CodeBlock
            key={parsedLines.length}
            language={codeLanguage}
            value={codeLines.join('\n')}
          />
        );
        codeLines = [];
      } else {
        // Hala bir kod bloğunun içindeyiz, bu satırı kaydet
        codeLines.push(line);
      }
    } else {
      if (line.startsWith('```')) {
        // Bir kod bloğunun başlangıcını bulduk, dil belirtecini kaydet
        isCodeBlock = true;
        codeLanguage = line.slice(3);
      } else {
        // Normal bir metin satırı, doğrudan ekleyebiliriz
        parsedLines.push(
          <React.Fragment key={parsedLines.length} >

            {line}
            <br />
          </React.Fragment>
        );
      }
    }
  });

  return parsedLines;
};

const Messagex = ({ message }) => {
  const lines = message.split('\n');
  const parsedLines = parseLines(lines);

  return <div className="message" >{parsedLines}</div> ;
};

const Message = ({ text = "", isMe, dot3, userInfo }) => {

  return (
    <Box
      display={"flex"}
      alignItems="center"
      gap="4"
      backgroundColor={"rgba(255, 255, 255, 0.15)"}
      p="3"
      borderBottomRadius={10}
      borderTopLeftRadius={isMe ? 0 : 10}
      borderTopRightRadius={isMe ? 10 : 0}
      mt="3"
    >
      <Image src={isMe ? userInfo?.profilePicture : images.chatbot} w={10} h={10} />
      <Text fontWeight={"400"} fontSize="16" color={"white"}>
        {dot3 ?
          <Box
            display={"flex"}
            alignItems="center"
            p="3"
            borderBottomRadius={10}
            mt="3"
          >
            <div className="col-3">
              <div className="snippet" data-title="dot-pulse">
                <div className="stage">
                  <div className="dot-pulse"></div>
                </div>
              </div>
            </div>
          </Box> :

          < Messagex message={text} />
        }


      </Text>
    </Box>
  );
};

export default Message;
