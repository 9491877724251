import React from "react";
import { Box, Text, Button } from "@chakra-ui/react";
import { AuthLayout, PasswordInput } from "../../components";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="center"
        h={"70%"}
      >
        <Box display={"flex"} flexDirection="column" w={"70%"}>
          <Text fontWeight={"500"} fontSize="28" color={"white"} mb="5">
            Reset Passsword
          </Text>
          <PasswordInput label="Password" />
          <PasswordInput label="Password Again" />
          <Button
            backgroundColor={"pink"}
            borderRadius={"10"}
            height={"48px"}
            isFullWidth
            my={"5"}
            onClick={() => navigate(routes.login.path)}
          >
            <Text color={"white"} fontWeight={"500"} fontSize={"16"}>
              Change
            </Text>
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ChangePassword;
