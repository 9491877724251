import React, { useState, useContext } from "react";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Text, Button, Divider, Link, useDisclosure } from "@chakra-ui/react";
import { AuthInput, AuthLayout, PasswordInput, LoadingModal } from "../../components";
import { forgotpassword } from "../../api/user";
import { UserContext } from "../../context/user";


const ForgotPassword = () => {
  const navigate = useNavigate();

  const [checkPost, setCheckPost] = useState(false)

  const [mail, setMail] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const loadingVideoModal = useDisclosure();

  const onSubmit = async () => {
    loadingVideoModal.onOpen();

    try {
      const response = await forgotpassword({ email: mail });
      //navigate(routes.verifyCode.path)
      if(response?.status == 200 || response?.status == 201 ) setCheckPost(true);
    } catch (error) {
      console.log(error);
    }
    finally {
      loadingVideoModal.onClose();
    }
  };

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="center"
        h={"70%"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            flexDirection="column"
            w={{ base: "100%", md: "90%", lg: "100%" }}
          >
            <Text fontWeight={"500"} fontSize="28" color={"white"} mb="5">
              Forgot Password
            </Text>
            <Divider mb={4} color={"lightGray"} />


            {checkPost ?

              <Text fontSize={"16"} fontWeight={"500"} color="gray" mb="7">
                Please check your e-mail box.
              </Text>

              :
              <>
                <Text fontSize={"16"} fontWeight={"500"} color="gray" mb="4">
                  We will send you a code to verify your account.
                </Text>
                <Divider mb={4} color={"lightGray"} />

                <AuthInput
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  _placeholder={{ opacity: 0.6 }}
                  onChange={(e) => setMail(e.target.value)}
                />
                <Button
                  backgroundColor={"pink"}
                  borderRadius={"10"}
                  height={"48px"}
                  isFullWidth
                  my={"5"}
                  type="submit"
                //onClick={() => navigate(routes.verifyCode.path)}
                >
                  <Text color={"white"} fontWeight={"500"} fontSize={"16"}>
                    Continue
                  </Text>
                </Button>
              </>
            }





          </Box>
        </form>
        <LoadingModal
          onClose={loadingVideoModal.onClose}
          isOpen={loadingVideoModal.isOpen}
        />
      </Box>
    </AuthLayout>
  );
};

export default ForgotPassword;
