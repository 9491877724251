import React from "react";

const CustomizedGenerationsActive = (props) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V7.65C17.6833 7.5 17.3583 7.37917 17.025 7.2875C16.6917 7.19583 16.35 7.125 16 7.075V2H2V11H6.2C6.35 11.45 6.6 11.8417 6.95 12.175C7.3 12.5083 7.69167 12.7417 8.125 12.875C8.075 13.2083 8.04167 13.5458 8.025 13.8875C8.00833 14.2292 8.025 14.5667 8.075 14.9C7.475 14.7833 6.9125 14.5625 6.3875 14.2375C5.8625 13.9125 5.4 13.5 5 13H2V16H8.325C8.44167 16.3667 8.575 16.7167 8.725 17.05C8.875 17.3833 9.05833 17.7 9.275 18H2ZM14.025 19L13.725 17.5C13.525 17.4167 13.3375 17.3292 13.1625 17.2375C12.9875 17.1458 12.8083 17.0333 12.625 16.9L11.175 17.35L10.175 15.65L11.325 14.65C11.2917 14.45 11.275 14.2333 11.275 14C11.275 13.7667 11.2917 13.55 11.325 13.35L10.175 12.35L11.175 10.65L12.625 11.1C12.8083 10.9667 12.9875 10.8542 13.1625 10.7625C13.3375 10.6708 13.525 10.5833 13.725 10.5L14.025 9H16.025L16.325 10.5C16.525 10.5833 16.7125 10.6708 16.8875 10.7625C17.0625 10.8542 17.2417 10.9667 17.425 11.1L18.875 10.65L19.875 12.35L18.725 13.35C18.7583 13.55 18.775 13.7667 18.775 14C18.775 14.2333 18.7583 14.45 18.725 14.65L19.875 15.65L18.875 17.35L17.425 16.9C17.2417 17.0333 17.0625 17.1458 16.8875 17.2375C16.7125 17.3292 16.525 17.4167 16.325 17.5L16.025 19H14.025ZM15.025 16C15.575 16 16.0458 15.8042 16.4375 15.4125C16.8292 15.0208 17.025 14.55 17.025 14C17.025 13.45 16.8292 12.9792 16.4375 12.5875C16.0458 12.1958 15.575 12 15.025 12C14.475 12 14.0042 12.1958 13.6125 12.5875C13.2208 12.9792 13.025 13.45 13.025 14C13.025 14.55 13.2208 15.0208 13.6125 15.4125C14.0042 15.8042 14.475 16 15.025 16Z"
        fill="#D10781"
      />
    </svg>
  );
};

export default CustomizedGenerationsActive;
