import * as React from "react";

const SvgNotification = (props) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.00003 20C10.3797 20 11.4989 18.8809 11.4989 17.5H6.5012C6.5012 18.8809 7.62034 20 9.00003 20ZM17.4137 14.152C16.659 13.341 15.2469 12.1211 15.2469 8.125C15.2469 5.08984 13.1188 2.66016 10.2492 2.06406V1.25C10.2492 0.559766 9.68987 0 9.00003 0C8.31018 0 7.75081 0.559766 7.75081 1.25V2.06406C4.88128 2.66016 2.75315 5.08984 2.75315 8.125C2.75315 12.1211 1.34104 13.341 0.586355 14.152C0.35198 14.4039 0.248074 14.7051 0.250027 15C0.254324 15.6406 0.757058 16.25 1.50393 16.25H16.4961C17.243 16.25 17.7461 15.6406 17.75 15C17.752 14.7051 17.6481 14.4035 17.4137 14.152Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

export default SvgNotification;
