/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from "react";
import { Box, Text, Image, Icon, useDisclosure } from "@chakra-ui/react";
import { DashBoardLayout, LoadingModal } from "../../components";
import images from "../../constants/images";
import { Instagram } from "../../components/icons";

const TemplatesDashBoard = () => {

  const url = `https://templateapi.orbina.ai/?${JSON.stringify(localStorage.getItem("token"))}`;
  //const url = `http://localhost:3001/?${JSON.stringify(localStorage.getItem("token"))}`;
  //JSON.stringify(localStorage.getItem("token"))

  const loadingVideoModal = useDisclosure();
  const iframeRef = useRef(null);

  useEffect(() => {
    document.title = 'Template | Orbina AI ';

    loadingVideoModal.onOpen();
    const iframe = iframeRef.current;
    if (iframe) {
      // LocalStorage'dan JWT token'ı okuyoruz
      const token = localStorage.getItem('token');
      iframe.contentWindow.postMessage({
        jwtToken: token
      }, '*');

      iframe.onload = () => {
        loadingVideoModal.onClose();
      };

    }
  }, []);
  return (
    <DashBoardLayout title="Templates" modalType = "template">
      <Box  w={"100%"} mt="6" >
        <iframe src={url} title="Frame Sayfası" ref={iframeRef} width="100%" height="900px"></iframe>
      </Box >
      <LoadingModal
        onClose={loadingVideoModal.onClose}
        isOpen={loadingVideoModal.isOpen}
      />
    </DashBoardLayout >
  );
};

export default TemplatesDashBoard;

const Row = ({ children }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={{ base: "column", lg: "row" }}
      justifyContent="space-between"
      gap={2}
      mt="3"
    >
      {children}
    </Box>
  );
};

const Ratio = ({ onClick = () => { }, isActive, text }) => {
  return (
    <Box
      borderWidth={isActive ? 1 : 0}
      borderColor="gray"
      borderRadius={10}
      my="4"
      w={{ base: "60%", sm: "100%", lg: "70%" }}
      p={2}
      onClick={onClick}
      cursor="pointer"
    >
      <Text fontWeight={"400"} fontSize={"16"} color={"gray"}>
        {text}
      </Text>
    </Box>
  );
};
