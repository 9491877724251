import React from "react";

const SvgQuiz = (props) => {
  return (
    <svg
      width="31"
      height="30"
      fontSize={30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 19.5C18.925 19.5 19.294 19.344 19.607 19.032C19.919 18.719 20.075 18.35 20.075 17.925C20.075 17.5 19.919 17.131 19.607 16.818C19.294 16.506 18.925 16.35 18.5 16.35C18.075 16.35 17.7065 16.506 17.3945 16.818C17.0815 17.131 16.925 17.5 16.925 17.925C16.925 18.35 17.0815 18.719 17.3945 19.032C17.7065 19.344 18.075 19.5 18.5 19.5ZM17.375 14.7H19.625C19.625 13.975 19.7 13.4435 19.85 13.1055C20 12.7685 20.35 12.325 20.9 11.775C21.65 11.025 22.15 10.4185 22.4 9.9555C22.65 9.4935 22.775 8.95 22.775 8.325C22.775 7.2 22.381 6.2815 21.593 5.5695C20.806 4.8565 19.775 4.5 18.5 4.5C17.475 4.5 16.5815 4.7875 15.8195 5.3625C15.0565 5.9375 14.525 6.7 14.225 7.65L16.25 8.475C16.475 7.85 16.7815 7.381 17.1695 7.068C17.5565 6.756 18 6.6 18.5 6.6C19.1 6.6 19.5875 6.7685 19.9625 7.1055C20.3375 7.4435 20.525 7.9 20.525 8.475C20.525 8.825 20.425 9.156 20.225 9.468C20.025 9.781 19.675 10.175 19.175 10.65C18.35 11.375 17.844 11.9435 17.657 12.3555C17.469 12.7685 17.375 13.55 17.375 14.7ZM9.5 24C8.675 24 7.969 23.7065 7.382 23.1195C6.794 22.5315 6.5 21.825 6.5 21V3C6.5 2.175 6.794 1.4685 7.382 0.8805C7.969 0.2935 8.675 0 9.5 0H27.5C28.325 0 29.0315 0.2935 29.6195 0.8805C30.2065 1.4685 30.5 2.175 30.5 3V21C30.5 21.825 30.2065 22.5315 29.6195 23.1195C29.0315 23.7065 28.325 24 27.5 24H9.5ZM9.5 21H27.5V3H9.5V21ZM3.5 30C2.675 30 1.969 29.7065 1.382 29.1195C0.794 28.5315 0.5 27.825 0.5 27V6H3.5V27H24.5V30H3.5Z"
        fill="black"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default SvgQuiz;
