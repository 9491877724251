import React from "react";

const Lock = ({ ...props }) => {
  return (
    <svg
      width="34"
      height="45"
      viewBox="0 0 34 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.25 44.75C3.08125 44.75 2.08108 44.3342 1.2495 43.5026C0.4165 42.6696 0 41.6688 0 40.5V19.25C0 18.0812 0.4165 17.0804 1.2495 16.2474C2.08108 15.4158 3.08125 15 4.25 15H6.375V10.75C6.375 7.81042 7.41129 5.30433 9.48388 3.23175C11.555 1.16058 14.0604 0.125 17 0.125C19.9396 0.125 22.4457 1.16058 24.5182 3.23175C26.5894 5.30433 27.625 7.81042 27.625 10.75V15H29.75C30.9188 15 31.9196 15.4158 32.7526 16.2474C33.5842 17.0804 34 18.0812 34 19.25V40.5C34 41.6688 33.5842 42.6696 32.7526 43.5026C31.9196 44.3342 30.9188 44.75 29.75 44.75H4.25ZM4.25 40.5H29.75V19.25H4.25V40.5ZM17 34.125C18.1688 34.125 19.1696 33.7092 20.0026 32.8776C20.8342 32.0446 21.25 31.0438 21.25 29.875C21.25 28.7062 20.8342 27.7054 20.0026 26.8724C19.1696 26.0408 18.1688 25.625 17 25.625C15.8313 25.625 14.8311 26.0408 13.9995 26.8724C13.1665 27.7054 12.75 28.7062 12.75 29.875C12.75 31.0438 13.1665 32.0446 13.9995 32.8776C14.8311 33.7092 15.8313 34.125 17 34.125ZM10.625 15H23.375V10.75C23.375 8.97917 22.7552 7.47396 21.5156 6.23438C20.276 4.99479 18.7708 4.375 17 4.375C15.2292 4.375 13.724 4.99479 12.4844 6.23438C11.2448 7.47396 10.625 8.97917 10.625 10.75V15Z"
        fill="black"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default Lock;
