import React, { useState, useContext,useEffect } from "react";
import { Box, Text, Button, Link, useDisclosure, Divider } from "@chakra-ui/react";
import { AuthLayout, AuthInput, PasswordInput, LoadingModal } from "../../components";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { register } from "../../api/user";
import { UserContext } from "../../context/user";
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const loadingVideoModal = useDisclosure();
  const [state, dispatch] = useContext(UserContext);
  const [user, setUser] = useState({ name: "", email: "", password: "", password2: "" });
  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const onSubmit = async (e) => {
    loadingVideoModal.onOpen();
    try {
      const response = await register(user);
      if (response?.status == 200 || response?.status == 201) {
        dispatch({ type: 'LOGIN', payload: response.data });
        navigate(routes.dashboardHome.path);
      }

    } catch (error) {
      console.log(error);
    }
    finally {
      loadingVideoModal.onClose();
    }
  };

  useEffect(() => {
    if (email) {
      setUser({ ...user, email: email });
    }
  }, [email]);

  return (
    <AuthLayout>
      <Box display={"flex"} justifyContent="center">

        <Box display={"flex"} flexDirection="column" w={"70%"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Text fontWeight={"500"} fontSize="28" color={"white"} mb="5">
              Sign Up
            </Text>
            <Divider mb={4} color={"lightGray"} />
            <AuthInput
              label="Name"
              placeholder="Enter your name"
              _placeholder={{ opacity: 0.6 }}
              onChange={(e) => {
                setUser({ ...user, name: e.target.value })
              }} />
            <Box mb={4}></Box>
            <AuthInput
              type="email"
              label="Email"
              placeholder="Enter your email"
              value={user?.email?.length > 0 ? user?.email : email}
              _placeholder={{ opacity: 0.6 }}
              onChange={(e) => setUser({ ...user, email: e.target.value })} />
            <Box mb={4}></Box>
            <PasswordInput
              label="Password"
              placeholder="Enter your password"
              _placeholder={{ opacity: 0.6 }}
              onChange={(e) => setUser({ ...user, password: e.target.value })} />
            <Box mb={4}></Box>
            <PasswordInput
              label="Confirm Password"
              placeholder="Enter your password again"
              _placeholder={{ opacity: 0.6 }}
              onChange={(e) => setUser({ ...user, password2: e.target.value })} />
            <Box mb={4}></Box>
            <Button
              backgroundColor={"pink"}
              borderRadius={"10"}
              height={"48px"}
              width={"100%"}
              my={"5"}
              type="submit"
            //onClick={() => navigate(routes.login.path)}
            >
              <Text color={"white"} fontWeight={"500"} fontSize={"16"}>
                Sign Up
              </Text>
            </Button>
            <Text
              fontSize={"14"}
              fontWeight={"400"}
              color="gray"
              textAlign={"center"}
            >
              Already have an account?{" "}
              <Link
                _hover={{ textDecoration: "none" }}
                color="gray"
                fontWeight={"400"}
                onClick={() => navigate(routes.login.path)}
              >
                <Text as='u'>Login.</Text>
              </Link>
            </Text>
          </form>
        </Box>
      </Box>
      <LoadingModal
        onClose={loadingVideoModal.onClose}
        isOpen={loadingVideoModal.isOpen}
      />
    </AuthLayout>
  );
};

export default Register;
