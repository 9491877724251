import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { SideBar, TopBar, Footer, UpdatePlanCard } from "..";
import { useMediaQuery } from "react-responsive";

const DashBoardLayout = ({ title = "", children,modalType,currentData }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showCard, setShowCard] = useState(true);

  const [hover, setHover] = useState(false);


  useEffect(() => {
    showPlanCard();
  });

  const showPlanCard = () => {
    switch (title) {
      case "Profile":
        setShowCard(false);
        break;
      case "Dashboard":
        setShowCard(false);
        break;
      default:
        setShowCard(true);
        break;
    }
  };

  return (
    <Box
      display={"flex"}
      position="static"
      minH={"100vh"}
      pt="2"
      pb={"2"}
      pl={4}
      bgGradient="linear(to-b, #091B39, #3A2D6B,#64309B,#79369C)"
    >
      {!isMobile && <SideBar onHoverChange={setHover} />}

      {!isMobile && <Box
        display={"flex"}
        flexDirection="column"
        ml={{ base: 0, md: 2, lg: 2 }}
        w={{ base: "12%", lg: "8%" }}
      > </Box>}

      <Box
        display={"flex"}
        flexDirection="column"
        ml={{ base: 0, md: 5, lg: 5 }}
        w={{ base: "95%", md: "83%", lg: "89%" }}
      >

        {hover && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            }}
          />
        )}

        <TopBar title={title} />
        {showCard && <UpdatePlanCard modalType={modalType} currentData={currentData} />}
        {children}
        <Footer />
      </Box>
    </Box>
  );
};

export default DashBoardLayout;
